import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import imgDarkWebEmail from './images/img-dark-web-email.svg';
import paymentRequired from './images/img_payment_required.svg';
import googlePay from './images/img_google_pay.svg';
import applePay from './images/img_apple_pay.svg';
import venmo from './images/img_venmo.svg';
import venmoButton from './images/img_venmo_button.svg';
import visa from './images/img_visa.svg';
import american_express from './images/img_amex.svg';
import discover from './images/img_discover.svg';
import genericCreditCard from './images/img_generic_credit_card.svg';
import paypal from './images/img_paypal.svg';
import masterCard from './images/img_mastercard.svg';
import imgGoogleStore from './images/img-google-play.svg';
import imgAppleStore from './images/img-appstore.svg';
import pinterestLogo from './images/img_social_pinterest.svg';
import facebookLogo from './images/img_social_facebook.svg';
import instagramLogo from './images/img_social_instagram.svg';
import linkedinLogo from './images/img_social_linkedin.svg';
import twitterLogo from './images/img_social_twitter.svg';
import beenverifiedLogo from './images/img_social_beenverified.svg';
import imgExampleAircraft from './images/img_example_aircraft.svg';
import imgExampleAutomobile from './images/img_example_automobile.svg';
import imgExampleWatercraft from './images/img_example_watercraft.svg';
import imgMonitoringBell from './images/img_bell_monitoring.svg';
import imgIdHub from './images/id-hub.svg';
import imgAddressGeneric from './images/img_address_generic_no_bg.svg';
import imgBell from './images/img_bell.svg';
import imgDarkWebScan from './images/img_dark_web_scan.svg';
import imgSpecializedBackgroundCheck from './images/specialized-background-check.svg';
import imgSpecializedUnclaimedMoney from './images/specialized-unclaimed-money.svg';
import imgReportRating from './images/img_report_rating.svg';
import imgSuccessfulUpgrade from './images/img_successful_upgrade.svg';
import imgComputerLimitReached from './images/img_computer_limit_reached.svg';
import img24hr from './images/img_24hr.svg';
import img_pdf_glow from './images/img_pdf_glow.svg';
import neighborSpoofing from './images/neighbor-spoofing.svg';
import imgNoProperty from './images/no-property-img-fallback.svg';
import imgMonitorBanner from './images/img_neighborhood_safety.svg';
import imgNHFacts from './images/img_neighborhood_facts.svg';
import imgMarketTrends from './images/img_market_trends.svg';
import imgAmenities from './images/img_amenities.svg';
import imgAnimatedPersonMagnifying from './images/ani_person_magnifying.gif';
import imgMagnifyingGlassNoResultsPage from './images/img_magnifying_glass_no_results.svg';
import imgMapDefaultPin from './images/img_map_default_pin.png';
import imgMapMainHomePin from './images/img_map_main_home_pin.svg';
import imgMapSecondaryPin from './images/img_map_secondary_pin.svg';
import imgWhatIsAVin from './images/img_what_is_a_vin.svg';
import imgWhatIsAVinV2 from './images/img_what_is_a_vin_v2.svg';
import imgWhereIsVin from './images/img_where_is_vin.svg';
import imgWhereIsVinV2 from './images/img_where_is_vin_v2.svg';
import imgCheckList from './images/icn_img_checklist.svg';
import imgAppStoreBadge from './images/img_apple_app_store_badge.svg';
import imgGooglePlayBadge from './images/img_badge_google_play.svg';
import imgMobileFreemium from './images/img_mobile_freemium.svg';
import imgContactlessIcon from './images/img_contactless_icon.svg';
import imgCopyIcon from './images/img_copy_icon.svg';
import imgEtagsLogo from './images/img_etags_logo.svg';
import imgMobileExpIcon from './images/img_mobile_exp_icon.svg';
import imgUpdatesIcon from './images/img_updates_icon.svg';
import imgMyAccountVin from './images/img_myaccount_vin.svg';
import imgCircleUser from './images/img_circle_user.svg';
import imgSeverityCritical from './images/severity-critical.svg';
import imgSeverityHigh from './images/severity-high.svg';
import imgSeverityLow from './images/severity-low.svg';
import imgSeverityMedium from './images/severity-medium.svg';
import imgSeverityNone from './images/severity-none.svg';
import imgSafety from './images/safety.svg';
import imgCampaign from './images/campaign.svg';
import imgClock from './images/clock.svg';
import imgDollar from './images/dollar.svg';
import imgEmission from './images/emission.svg';
import imgPremiumBadge from './images/img_premium_badge.svg';
import imgTitleRegistration from './images/img_title_registration.svg';
import imgTitleChecks from './images/img_title_checks.svg';
import accidentsCoverageMapImage from './images/accident_coverage_map.png';
import rewardsBannerMobile from './images/rewards_banner_mobile.png';
import rewardsBannerDesktop from './images/rewards_banner_desktop.png';
import imgNMVTISLogo from './images/img_nmvtis_logo.svg';
import imgNoVehicleImageProvided from './images/no-vehicle-image-provided.svg';
import imgExpiredEmailLink from './images/img_expired_email_link.svg';
import imgFallbackProperty from './images/img_fallback_property.svg';
import imgPlaceholderSexOffender from './images/img_placeholder_sex_offender.svg';
import imgMyGarageBrake from './images/img_mygarage_brake.svg';
import imgMyGarageOilChange from './images/img_mygarage_oilchange.svg';
import imgMyGarageSellYourCar from './images/img_mygarage_sellyourcar.svg';
import imgMyGarageStar from './images/img_mygarage_starfilled.svg';
import imgMyGarageTireChange from './images/img_mygarage_tirechange.svg';
import imgMyGarageTitleCheck from './images/img_mygarage_titlecheck.svg';
import imgAddVehicle from './images/add-vehicle.svg';
import imgFallbackSexOffender from './images/img_placeholder_sex_offender.svg';
import verificationEmail from './images/verification-email.svg';
import { BetaPill } from 'components/Language/BetaPill';
import ComplianceLogo from './images/img-alert.svg';
import SearchLogo from './images/search-logo.svg';
import imgCustomerService from './images/img_customer_service.svg';
import myGarageCtaImage from './images/my_garage_cta_image.svg';
import rewardsCtaImage from './images/rewards_cta_image.svg';
import imgMapBoxPin from './images/img_pin.svg';
import IdMonitorBvLogo from 'images/id-monitor-bv-logo.svg';
import imgAreaPin from './images/img_areapin.svg';
import imgMailBox from './images/img_mailbox.svg';
import imgReferralsIcon from './images/img_referrals_icon.svg';
import imgToggleToSatellite from './images/btn-map-satellite.png';
import imgToggleToStreet from './images/btn-map-street.png';
import imgDashMoney from './images/img_dash_money.svg';
import imgDashBell from './images/img_dash_bell.svg';
import imgDashHacker from './images/img_dash_hacker.svg';
import imgDashId from './images/img_dash_id.svg';
import imgDashPrize from './images/img_dash_prize.svg';
import imgDashUser from './images/img_dash_user.svg';
import imgDashLanguage from './images/img_dash_language.svg';
import imgDashFamilyTree from './images/img_dash_family_tree.svg';
import imgDashFraud from './images/img_dash_fraud.svg';
import imgPerksBanner from './images/rewards_banner_desktop.png';
import imgPerksBannerMob from './images/rewards_banner_mobile.png';
import imgRadialCar from './images/img-radial-car.svg';
import imgDefaultContactSearchMessage from './images/default-contact-search-message.svg';
import imgNoContactResultsFound from './images/no-contact-results-found.svg';
import imgContactSearchRightArrow from './images/contact-search-right-arrow.svg';
import imgSaveListProperty from './images/img_save_list_property.svg';
import imgPremiumFrame from './images/img-premium-frame.svg';
import imgPremiumSearchIcon from './images/img-premium-search-icon.svg';
import imgCheckIcon from './images/img-check-icon.svg';
import imgCloseIcon from './images/img-close-icon.svg';
import imgRelativesQuickAdd from './images/relatives_quick_add.svg';
import imgPetCreator from './images/pet-creator.svg';
import imgRelativesSearch from './images/relatives_search.svg';
import imgFamilyTreeStarsIcon from './images/family-tree-stars-icon.svg';
import imgFamilySearch from './images/family-search.svg';
import imgFamilyTreeAILogo from './images/family-tree-ai-logo.svg';
import imgFamilyTreeBuilder from './images/family-tree-tree-builder.svg';
import imgFamilyTreeBackground from './images/family-tree-background.svg';
import imgFamilyTreePlaceholder from './images/family-tree-placeholder.svg';
import imgFamilyTreeAddRelative from './images/family-tree-add-relative.svg';
import imgFamilyTreeDeceased from './images/family-tree-deceased.svg';
import imgFamilyTreePetIcon from './images/family-tree-pet-icon.svg';
import imgFamilyTreeOriginalMember from './images/family-tree-original-member.svg';
import imgTreeOfLife from './images/img_tree_of_life.svg';
import imgSingleTree from './images/img_single_tree.svg';
import imgInfinity from './images/img_infinity.svg';
import imgPersonSearch from './images/img_person_search.svg';
import imgRadialProperty from './images/img-radial-property.svg';
import imgAstrologyNumerology from './images/img_astrology_numerology.svg';
import imgAstrologyNumerologyV2 from './images/img_astrology_numerology_v2.svg';
import imgAstrologyNumerologyLoading from './images/img_astrology_numerology_loading.svg';
import imgAstrologyStandAloneOnboarding from './images/ast-standalone-onboarding-bg.svg';
import imgRocket from './images/rocket.svg';
import premiumModalCrown from './images/premiumModal-crown.svg';
import premiumModalBanner from './images/premiumModal-banner.svg';
import premiumModalBannerMob1 from './images/premiumModal-banner-mob1.svg';
import premiumModalBannerMob2 from './images/premiumModal-banner-mob2.svg';
import premiumModalPersonIcon from './images/premiumModal-person.svg';
import premiumModalPhoneIcon from './images/premiumModal-phone.svg';
import premiumModalPropertyIcon from './images/premiumModal-property.svg';
import imgFamilyTreeCheckIcon from './images/checked.svg';
import imgFamilyTreeMapBackground from './images/map-background.svg';
import imgFamilyTreeMapPin from './images/map-pin.svg';
import imgFamilyTreeMapDeceseased from './images/map-deceased.svg';
import imgFamilyTreeSearch from './images/family-tree-search-img.svg';
import imgFamilyTree from './images/family-tree-tree-img.svg';
import imgFamilyTreeNames from './images/family-tree-names-img.svg';
import imgFamilyTreeMainDetails from './images/family-tree-main-details-img.svg';
import imgFamilyTreeMapPinMarker from './images/map-pin-marker.svg';
import relativeInsightIcon from './images/relative-insight-icon.svg';
import imgFamilySearchIcon from './images/family-search-icon.svg';
import imgFamilySearchTreeIcon from './images/family-search-tree-icon.svg';
import downsellModalBackground from './images/Circle-gradient-bg.svg';
import downsellSpecialOfferImg from './images/downsellSpecialOffer.svg';
import fourMainPersonalityTypes from './images/four-main-personality-types.svg';
import fourMainPersonalityTypesMobile from './images/four-main-personality-types-mobile.svg';
import personalityColorBanner from './images/personality-color-banner.svg';
import personalityColorBar from './images/personality-color-bar.svg';
import colorBar from './images/color-bar.svg';
import personalityTraitsLogo from './images/personality-traits-logo.svg';
import analyst from './images/personality-traits-analyst.svg';
import diplomats from './images/personality-traits-diplomat.svg';
import explorers from './images/personality-traits-explorer.svg';
import sentinels from './images/personality-traits-sentinel.svg';
import agreeableness from './images/personality-traits-agreeableness.svg';
import conscientiousness from './images/personality-traits-conscientiousness.svg';
import extraversion from './images/personality-traits-extraversion.svg';
import neuroticism from './images/personality-traits-neuroticism.svg';
import openness from './images/personality-traits-openness.svg';
import stability from './images/personality-traits-stability.svg';
import compliance from './images/personality-traits-compliance.svg';
import dominance from './images/personality-traits-dominance.svg';
import influence from './images/personality-traits-influence.svg';
import generalPhoto from './images/sponsored-agent-photo.png';
import timPhoto from './images/sponsored-agent-photo-tim.png';
import yingPhoto from './images/sponsored-agent-photo-ying.png';
import imgSingleSignOnCta from './images/img_sso_cta.svg';
import imgFlowerObituary from './images/flowerObituary.svg';
import ownerSearchGenericCta from './images/owner-search-generic-cta.svg';
import imgFraudCheck from './images/img_fraud_check.svg';
import imgCakeRounded from './images/cake-rounded.svg';
import imgAncestorReportFamilyIcon from './images/ancestor-report-family.svg';
import errorComputer from './images/error-computer.svg';
import personalityTraitsLoading from './animations/personality-traits-loading.json';
import personalityTraitType from './images/personality-trait-type.svg';
import personalityTraitsModalDots from './images/img-dots.svg';
import personalityTraitsModal from './images/img-pt-modal-header.png';
import cancelDownsellBackground from './images/cancel-downsell-bg.svg';
import cancelDownsellBackgroundMobile from './images/cancel-downsell-bg-mobile.svg';
import trustPilotIcon from './images/trustpilot.svg';
import CriminalorTraffic from './images/imgCriminalTraffic.svg';
import bankrupticies from './images/imgBankruptcies.svg';
import Licenses from './images/imgLicenses.svg';
import MonitoringBell from './images/imgMonitoringBell.svg';
import AstrologyIcon from './images/astrology-icon.svg';
import { BVLogo } from 'icons';
import staypal from './images/staypal.svg';
import staypalOnline from './images/staypal-online.svg';
import ClaimReport from './images/imgClaimReport.svg';
import ClaimedReport from './images/imgClaimedReport.svg';
import imgNoticeLeave from './images/img_notice_leave.svg';
import imgBellCurve from 'images/bell_curve.png';
import imgVerticalArrow from 'images/vertical_arrow.png';
import imgMigrationCta from 'images/img_migration_cta.svg';
import imgUnclaimedMoneyBannerLogo from 'images/img_unclaimed_money_banner_logo.png';
import imgFamilySearchAncestor from 'images/img_family_search_ancestor.svg';
import reactivationBackground from 'images/reactivation-bg.svg';
import reactivationBackgroundPaused from 'images/reactivation-bg-paused.svg';
import reactivationBackgroundMobile from 'images/reactivation-bg-mobile.svg';
import reactivationBackgroundPausedMobile from 'images/reactivation-bg-paused-mobile.svg';
import reactivationDataIcon from 'images/reactivation-data-icon.svg';
import reactivationDataIconPaused from 'images/reactivation-data-icon-paused.svg';
import reactivationFireIcon from 'images/reactivation-fire-icon.svg';
import reactivationMembershipIcon from 'images/reactivation-membership-icon.svg';
import reactivationSwitchPlanIcon from 'images/reactivation-switch-plan-icon.svg';
import { CustomIcons, Stack } from '@ltvco/refresh-lib/theme';
import { type FilterTypeOption, PillList } from '@ltvco/refresh-lib/v1';
import {
  List,
  FacebookRounded,
  Instagram,
  LinkedIn,
  Pinterest,
  Person,
  Phone,
  Home,
  Email,
  AlternateEmail,
  DirectionsCar,
  Restore,
  Public,
  Radar,
  Psychology,
  FlareRounded,
  LocalFlorist,
  Redeem,
  NotificationsNone,
  PersonPinCircle,
  DirectionsCarRounded,
  AttachMoney,
  MapRounded,
  Work,
  AccountCircle,
  PersonAdd,
  Summarize,
  Search,
  Lightbulb,
  FamilyRestroom,
  NotificationsActive,
  ManageAccounts,
} from '@mui/icons-material';
import { type SideMenuItem } from '@ltvco/refresh-lib/v2';
import imgFamilyTreeCameraIcon from 'images/family-tree-camera-icon.svg';
import imgFamilyTreeCloudUploadIcon from 'images/family-tree-cloud-upload-icon.svg';
import imgFamilyTreePhotoPlaceholderIcon from 'images/family-tree-photo-placeholder-icon.svg';
import imgFamilyAlbumEmpty from 'images/family-album-empty.svg';
import compatibilityImg from 'images/ast-compatibility.svg';
import aries from 'images/ast-aries.svg';
import taurus from 'images/ast-taurus.svg';
import gemini from 'images/ast-gemini.svg';
import cancer from 'images/ast-cancer.svg';
import leo from 'images/ast-leo.svg';
import virgo from 'images/ast-virgo.svg';
import libra from 'images/ast-libra.svg';
import scorpio from 'images/ast-scorpio.svg';
import sagittarius from 'images/ast-sagittarius.svg';
import capricorn from 'images/ast-capricorn.svg';
import aquarius from 'images/ast-aquarius.svg';
import pisces from 'images/ast-pisces.svg';
import empty from 'images/ast-empty.png';
import emptyBackground from 'images/ast-numerology.svg';
import smallAries from 'images/ast-small-aries.svg';
import smallTaurus from 'images/ast-small-taurus.svg';
import smallGemini from 'images/ast-small-gemini.svg';
import smallCancer from 'images/ast-small-cancer.svg';
import smallLeo from 'images/ast-small-leo.svg';
import smallVirgo from 'images/ast-small-virgo.svg';
import smallLibra from 'images/ast-small-libra.svg';
import smallScorpio from 'images/ast-small-scorpio.svg';
import smallSagittarius from 'images/ast-small-sagittarius.svg';
import smallCapricorn from 'images/ast-small-capricorn.svg';
import smallAquarius from 'images/ast-small-aquarius.svg';
import smallPisces from 'images/ast-small-pisces.svg';
import smallAscendant from 'images/ast-small-ascendant.svg';
import bvLogoPng from 'images/bv_logo_png.png';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const reportTypeFilterOptions: { [key: string]: FilterTypeOption } = {
  monitored_report: {
    id: 'monitored_report',
    display: 'Monitored',
    eventLabel: 'Monitored',
    icon: <MonitoringBell />,
    divider: true,
  },
  detailed_person_report: {
    id: 'detailed_person_report',
    display: 'Person',
    eventLabel: 'Person',
  },
  reverse_phone_report: {
    id: 'reverse_phone_report',
    display: 'Phone',
    eventLabel: 'Phone',
  },
  property_report: {
    id: 'property_report',
    display: 'Property',
    eventLabel: 'Property',
  },
  vehicle_report: {
    id: 'vehicle_report',
    display: 'Vehicle',
    eventLabel: 'Vehicle',
  },
  social_network_report: {
    id: 'social_network_report',
    display: 'Email',
    eventLabel: 'Email',
  },
  username_report: {
    id: 'username_report',
    display: 'Username',
    eventLabel: 'Username',
  },
  sex_offender_report: {
    id: 'sex_offender_report',
    display: 'Neighborhood',
    eventLabel: 'Neighborhood',
  },
  obituary_report: {
    id: 'obituary_report',
    display: 'Obituary',
    eventLabel: 'Obituary',
  },
  ancestry_report: {
    id: 'ancestry_report',
    display: 'Ancestor',
    eventLabel: 'Ancestor',
  },
  unclaimed_money_report: {
    id: 'unclaimed_money_report',
    display: 'Unclaimed Money',
    eventLabel: 'Unclaimed Money',
  },
  contact_report: {
    id: 'contact_report',
    display: 'Contact Pro',
    eventLabel: 'Contact',
  },
  phone_fraud_report: {
    id: 'phone_fraud_report',
    display: 'Phone Fraud',
    eventLabel: 'Phone Fraud',
  },
  email_fraud_report: {
    id: 'email_fraud_report',
    display: 'Email Fraud',
    eventLabel: 'Email Fraud',
  },
  ip_fraud_report: {
    id: 'ip_fraud_report',
    display: 'IP Fraud',
    eventLabel: 'IP Fraud',
  },
  url_fraud_report: {
    id: 'url_fraud_report',
    display: 'URL Fraud',
    eventLabel: 'Url Fraud',
  },
};

// TODO: should we extend the default config the same way we're doing with the theme? might be good for some props, other props maybe should be required
export const constants = {
  images: {
    imgClaimReport: ClaimReport,
    imgClaimedReport: ClaimedReport,
    imgMonitoring: MonitoringBell,
    brandLogo: logo,
    brandLogoMobile: logoSmall,
    imgAppleStore,
    imgGoogleStore,
    master: masterCard,
    visa: visa,
    american_express: american_express,
    discover: discover,
    genericCreditCard: genericCreditCard,
    paypal: paypal,
    googlePay: googlePay,
    paymentRequired: paymentRequired,
    applePay: applePay,
    venmo: venmo,
    venmoButton: venmoButton,
    searchLogo: SearchLogo,
    complianceLogo: ComplianceLogo,
    imgDarkWebEmail: imgDarkWebEmail,
    imgNoProperty,
    imgExampleAircraft: imgExampleAircraft,
    imgExampleWatercraft: imgExampleWatercraft,
    imgExampleAutomobile: imgExampleAutomobile,
    monitoringBell: imgMonitoringBell,
    imgIdHub,
    imgAddressGeneric,
    imgBell,
    imgDarkWebScan,
    imgSpecializedBackgroundCheck,
    imgSpecializedUnclaimedMoney,
    imgReportRating: imgReportRating,
    imgSuccessfulUpgrade: imgSuccessfulUpgrade,
    imgComputerLimitReached: imgComputerLimitReached,
    pdfLogo: img_pdf_glow,
    img24hr,
    neighborSpoofing,
    imgAnimatedPersonMagnifying,
    imgMagnifyingGlassNoResultsPage: imgMagnifyingGlassNoResultsPage,
    imgMapDefaultPin: imgMapDefaultPin,
    imgMapMainHomePin: imgMapMainHomePin,
    imgMapSecondaryPin: imgMapSecondaryPin,
    imgMonitorBanner: imgMonitorBanner,
    imgMobileExpIcon: imgMobileExpIcon,
    imgCopyIcon: imgCopyIcon,
    imgContactlessIcon: imgContactlessIcon,
    imgUpdatesIcon: imgUpdatesIcon,
    imgEtagsLogo: imgEtagsLogo,
    imgAddVehicle: imgAddVehicle,
    imgNHFacts: imgNHFacts,
    imgMarketTrends: imgMarketTrends,
    imgAmenities: imgAmenities,
    imgWhereIsVin: imgWhereIsVin,
    imgMyAccountVin: imgMyAccountVin,
    imgWhereIsVinV2: imgWhereIsVinV2,
    imgWhatIsAVin: imgWhatIsAVin,
    imgWhatIsAVinV2: imgWhatIsAVinV2,
    imgCheckList,
    imgAppStoreBadge,
    imgGooglePlayBadge,
    imgMobileFreemium,
    imgFraudCheck,
    ownershipHistoryImages: {
      imgCircleUser,
    },
    recallImages: {
      imgSeverityCritical,
      imgSeverityHigh,
      imgSeverityLow,
      imgSeverityMedium,
      imgSeverityNone,
      imgClock,
      imgDollar,
      imgEmission,
      imgSafety,
      imgCampaign,
    },
    imgPremiumBadge,
    imgTitleRegistration,
    imgTitleChecks,
    accidentsCoverageMapImage,
    rewardsBannerMobile,
    rewardsBannerDesktop,
    myGarageCtaImage,
    rewardsCtaImage,
    imgFallbackSexOffender,
    verificationEmail,
    imgMapBoxPin,
    imgAreaPin,
    imgMailBox,
    imgReferralsIcon,
    imgCustomerService,
    imgExpiredEmailLink,
    imgFallbackProperty,
    imgPlaceholderSexOffender,
    imgNoVehicleImageProvided,
    imgDashMoney,
    imgDashBell,
    imgDashHacker,
    imgDashId,
    imgDashPrize,
    imgDashUser,
    imgDashLanguage,
    imgDashFamilyTree,
    imgDashFraud,
    imgNoticeLeave,
    imgMyGarage: {
      imgMyGarageBrake: imgMyGarageBrake,
      imgMyGarageOilChange: imgMyGarageOilChange,
      imgMyGarageSellYourCar: imgMyGarageSellYourCar,
      imgMyGarageStar: imgMyGarageStar,
      imgMyGarageTireChange: imgMyGarageTireChange,
      imgMyGarageTitleCheck: imgMyGarageTitleCheck,
    },
    nmvtisLogo: imgNMVTISLogo,
    imgToggleToSatellite,
    imgToggleToStreet,
    imgPerksBanner,
    imgPerksBannerMob,
    imgRadialCar,
    imgDefaultContactSearchMessage,
    imgNoContactResultsFound,
    imgContactSearchRightArrow,
    imgSaveListProperty,
    imgPremiumData: {
      imgPremiumFrame,
      imgPremiumSearchIcon,
      imgCheckIcon,
      imgCloseIcon,
    },
    imgFlowerObituary,
    imgCakeRounded,
    familyTreeImages: {
      imgRelativesQuickAdd,
      imgPetCreator,
      imgRelativesSearch,
      imgFamilyTreeBackground,
      imgFamilyTreePlaceholder,
      imgFamilyTreeAddRelative,
      imgFamilyTreeDeceased,
      imgFamilyTreePetIcon,
      imgFamilyTreeOriginalMember,
      imgFamilyTreeCheckIcon,
      imgFamilyTreeMapBackground,
      imgFamilyTreeMapPin,
      imgFamilyTreeMapDeceseased,
      imgFamilyTreeSearch,
      imgFamilyTree,
      imgFamilyTreeNames,
      imgFamilyTreeMainDetails,
      imgFamilyTreeMapPinMarker,
      imgFamilySearchIcon,
      imgFamilySearchTreeIcon,
      imgFamilyTreeBuilder,
      imgFamilyTreeAILogo,
      imgFamilyTreeStarsIcon,
      imgFamilySearch,
      imgFamilyTreeCameraIcon,
      imgFamilyTreeCloudUploadIcon,
      imgFamilyTreePhotoPlaceholderIcon,
      imgFamilyAlbumEmpty,
      bvLogoPng,
    },
    ancestor: {
      imgAncestorReportFamilyIcon,
    },
    imgTreeOfLife,
    imgSingleTree,
    imgInfinity,
    imgPersonSearch,
    imgRadialProperty,
    astrology: {
      imgAstrologyNumerology,
      imgAstrologyNumerologyV2,
      imgAstrologyNumerologyLoading,
      compatibilityImg,
      aries,
      taurus,
      gemini,
      cancer,
      leo,
      virgo,
      libra,
      scorpio,
      sagittarius,
      capricorn,
      aquarius,
      pisces,
      empty,
      emptyBackground,
      smallAries,
      smallTaurus,
      smallGemini,
      smallCancer,
      smallLeo,
      smallVirgo,
      smallLibra,
      smallScorpio,
      smallSagittarius,
      smallCapricorn,
      smallAquarius,
      smallPisces,
      smallAscendant,
    },
    astrologyStandalone: {
      imgOnboardingBg: imgAstrologyStandAloneOnboarding,
      AstrologyIcon,
    },
    imgRocket,
    premiumModalCrown,
    premiumModalBanner,
    premiumModalBannerMob1,
    premiumModalBannerMob2,
    premiumModalPersonIcon,
    premiumModalPhoneIcon,
    premiumModalPropertyIcon,
    relativeInsightIcon,
    downsellModalBackground,
    downsellSpecialOfferImg,
    fourMainPersonalityTypes,
    fourMainPersonalityTypesMobile,
    personalityColorBanner,
    personalityColorBar,
    colorBar,
    personalityTraitsLogo,
    errorComputer,
    personalityTraitsModal,
    personalityTraitType,
    personalityTraitsModalDots,
    personalityTraits: {
      analyst,
      diplomats,
      explorers,
      sentinels,
      agreeableness,
      conscientiousness,
      extraversion,
      neuroticism,
      openness,
      stability,
      compliance,
      dominance,
      influence,
    },
    sponsoredAgentPhoto: {
      timPhoto,
      yingPhoto,
      generalPhoto,
    },
    imgSingleSignOnCta,
    ownerSearchGenericCta,
    cancelDownsellBackground,
    cancelDownsellBackgroundMobile,
    trustPilotIcon,
    CourtRecordsImages: {
      imgCriminalTraffic: CriminalorTraffic,
      imgBankruptcies: bankrupticies,
      imgLicenses: Licenses,
    },
    staypal,
    staypalOnline,
    imgBellCurve,
    imgVerticalArrow,
    imgMigrationCta,
    imgUnclaimedMoneyBannerLogo,
    imgFamilySearchAncestor,
    reactivationRedesignImages: {
      reactivationBackground,
      reactivationBackgroundPaused,
      reactivationBackgroundMobile,
      reactivationBackgroundPausedMobile,
      reactivationDataIcon,
      reactivationDataIconPaused,
      reactivationFireIcon,
      reactivationMembershipIcon,
      reactivationSwitchPlanIcon,
    },
  },

  animations: {
    personalityTraitsLoading,
  },

  links: {
    baseUrl: `${baseUrl}${
      import.meta.env.VITE_PATH_PREFIX?.includes('/')
        ? import.meta.env.VITE_PATH_PREFIX
        : `/${import.meta.env.VITE_PATH_PREFIX}`
    }`,
    joinLink: 'https://www.beenverified.com/lp/32fc4f/4/subscribe',
    brandLink: import.meta.env.VITE_DEV_PROXY_SEARCH_PERSON_TEASER
      ? ''
      : `${baseUrl}`,
    aboutLink: 'https://beenverified.com/about',
    fcraLink: 'https://www.beenverified.com/about/fcra/',
    doDontsLink: 'https://www.beenverified.com/about/dos-donts/',
    tosLink: 'https://www.beenverified.com/faq/terms-conditions/',
    privacyLink: 'https://www.beenverified.com/faq/privacy/',
    contactLink: 'https://www.beenverified.com/contact/',
    FaqLink: 'https://beenverified.com/faq/',
    dontSellInfoLink: 'https://www.beenverified.com/svc/optout/search/optouts',
    affiliateLink: 'https://www.beenverified.com/affiliates',
    peopleSearchLink: 'https://www.beenverified.com/people/',
    reversePhoneLink: 'https://www.beenverified.com/reverse-phone/',
    emailLookupLink: 'https://www.beenverified.com/email-search/',
    reverseAddressLink: 'https://www.beenverified.com/reverse-address-lookup/',
    contactForm: 'https://www.beenverified.com/contact/',
    supportLink: 'http://support.beenverified.com/',
    twitterLink: '',
    facebookLink: '',
    blogLink: '',
    brandIosUrl:
      'https://apps.apple.com/us/app/beenverified-background-check/id342585873?utm_source=beenverified&utm_medium=internal&utm_campaign=',
    brandAndroidUrl:
      'https://play.google.com/store/apps/details?id=com.beenverified.android&hl=en_US&utm_source=beenverified&utm_medium=internal&utm_campaign=',
    rapidsLookupLink: 'https://rpds.beenverified.com/rpds/lookup',
    etagsPartnerUrl: 'https://www.etags.com/app/us?utm_source=bv',
    mrRogersBaseUrl: '/ss/mrg',
    billingLink: 'https://www.beenverified.com/faq/billing/',
    customPlanLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSdsuNU5p7oD3CX3VvEMrBP1kvG3AodnhSvDytnb36rDVpcpxg/viewform',
    mapboxStyleUrl: 'mapbox://styles/beenverified/cloywa73e00sd01qnb8162waw',
    dataDeckRootURI: 'hk/dd/v2',
    marketplaceBaseUrl: 'https://www.bumper.com/marketplace/',
    accidentDisclaimerURL:
      'https://support.beenverified.com/hc/en-us/articles/16578808406811',
    chatLink: 'https://www.beenverified.com/chat/',
    vehicleReportInquiryURL:
      'https://www.beenverified.com/contact/vehicle-history-report-inquiry/',

    nmvtisGlossaryURL: 'https://vehiclehistory.bja.ojp.gov/nmvtis_glossary',
    nmvtisStatesURL: 'https://vehiclehistory.bja.ojp.gov/nmvtis_states',
    nmvtisDataProvidersURL:
      'https://vehiclehistory.bja.ojp.gov/nmvtis_vehiclehistory',
    nmvtisVehicleHistoryURL: 'http://vehiclehistory.bja.ojp.gov/',
    topHapBaseUrl: 'https://widgets.tophap.com/api/widgets',
    monocleLeadsEndpoint: 'https://www.beenverified.com/mcl/v1/leads',
    cancelSurveyLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSc7YnY1xB6fOht_hHjVb4jSk2Wh5JpdM7ywEAgdZrkm2ubt8Q/viewform',
    propertySummaryFeedbackLink:
      'https://docs.google.com/forms/d/e/1FAIpQLScb1X-yqATHYt4_tGltJiziEKmhsk8UmIEW0EDsEyPRwvcoxQ/viewform',
    propertySmartInsightsSurvey: 'https://forms.gle/RyJoGSwCL3xqpr2v6',
    propertyAdvertiseLink: 'https://forms.gle/iBPaZhfMGbSwZpfR9',
    setupSsoLink: 'https://www.beenverified.com/sso_connections',
  },
  featureFlags: {
    hasCriminalEnabled: true,
    hasSmartInsightsAutoLoadEnabled: true,
    propertyReportFlags: ['include_ca_data'],
    enableListExport: true,
    familyTreeEnabled: true,
    personReportSummaryEnabled: false,
    relativesInsightsEnabled: false,
    obituaryParserEnabled: true,
    familySearchEnabled: true,
    hasConsolidatedSearch: true,
    smartPersonSearchEnabled: true,
    hasReportPreferencesEnabled: true,
    hasMobileSearchHeaderRedesignEnabled: true,
  },
  data: {
    brandName: 'BeenVerified',
    brandAbbreviation: 'bv' as 'bv',
    brandSupportEmail: 'support@beenverified.com',
    legalName: 'BeenVerified, LLC',
    supportPhone: '1-844-823-2869',
    customerSupportPhone: '1-888-579-5910',
    supportEmail: 'support@beenverified.com',
    complianceEmail: 'compliance@beenverified.com',
    pdfPrice: '$4.95',
    rewardsDisclaimer:
      'Certain products and services, including vehicle purchase/sale opportunities, provided above include offers from third parties and/or external partners from whom we may receive limited compensation. Please be advised that these offers are provided as a convenience and are a sample, but do not necessarily include ALL offers, products or services available in the marketplace.',
    carGeniusPhone: '1-877-418-2717',
    upgradePlanCustomSolutionHeading: 'Need more reports or users?',
    upgradePlanCustomSolutionDescription: 'Let’s talk',
    limitReachedSubHeading:
      'We offer different solutions to match your search needs',
    contactOptionsCustomPlanDisplay: 'Request larger plan',
    premiumModalCardData: [
      {
        title: 'Person Search',
        icon: premiumModalPersonIcon,
        content:
          'Dive deeper with possible contact information, criminal & address history, etc.',
      },
      {
        title: 'Phone Search',
        icon: premiumModalPhoneIcon,
        content: 'See who might be behind the numbers',
      },
      {
        title: 'Property Search',
        icon: premiumModalPropertyIcon,
        content: 'Discover more about addresses & neighborhoods',
      },
    ],
    premiumModalContent:
      "Looks like you're missing out on key searches, like person search. Unlock premium data for access to person, phone, email, property, dark web, unclaimed money searches and more!",
    validReportCountThreshold: 5,
    plansWithout5ReportBlocker: [
      'Referral_User_0_1_month_freemium_limit3',
      '7_day_trial_1_00_up_front_28_79_recurring_monthly_freshness_limit_100_vin_no_pdf_vehicle_only',
      '7_day_trial_5_00_up_front_28_79_recurring_monthly_freshness_limit_100_vin_free_pdf_vehicle_only',
    ],
    blockUpgradeWindowThreshold: 24,
    smartSearchV1FeedbackLink: 'https://forms.gle/dxxWWFPH326CtnEm8',
    copyrightText:
      '© 2024 BeenVerified, LLC. All rights reserved. BeenVerified® and the BeenVerified star mascot are registered trademarks of BeenVerified, LLC',
    defaultSearchFormEventName: 'openPersonSearch',
    brandCustomerSupportSchedule: {
      days: 'Monday - Friday',
      hours: '9am - 6pm EST',
    },
  },
  config: {
    socialMedia: [
      {
        textContent: 'Twitter',
        alternateTextContent: '@BeenVerified',
        href: 'https://twitter.com/BeenVerified',
        imgLogoUrl: twitterLogo,
        icon: <CustomIcons.TwitterIcon sx={{ color: 'primary' }} />,
        hideOnSide: false,
        hideinNeedAssistanceNewDesign: false,
      },
      {
        textContent: 'Facebook',
        alternateTextContent: 'facebook.com/Beenverified',
        href: 'https://www.facebook.com/BeenVerified',
        imgLogoUrl: facebookLogo,
        icon: <FacebookRounded fontSize="small" sx={{ color: '#0052FF' }} />,
        hideOnSide: false,
        hideinNeedAssistanceNewDesign: false,
      },
      {
        textContent: 'Instagram',
        alternateTextContent: 'instagram.com/BeenVerified',
        href: 'https://www.instagram.com/beenverified/',
        imgLogoUrl: instagramLogo,
        icon: <Instagram fontSize="small" sx={{ color: '#0052FF' }} />,
        hideOnSide: true,
        hideinNeedAssistanceNewDesign: false,
      },
      {
        textContent: 'Linkedin',
        alternateTextContent: 'linkedin.com/company/beenverified-com',
        href: 'https://www.linkedin.com/company/beenverified-com',
        imgLogoUrl: linkedinLogo,
        icon: <LinkedIn fontSize="small" sx={{ color: '#0052FF' }} />,
        hideOnSide: true,
        hideinNeedAssistanceNewDesign: false,
      },
      {
        textContent: 'Pinterest',
        href: 'https://www.pinterest.com/beenverified/',
        imgLogoUrl: pinterestLogo,
        icon: <Pinterest fontSize="small" sx={{ color: '#0052FF' }} />,
        hideOnSide: true,
        hideinNeedAssistanceNewDesign: false,
      },
      {
        textContent: 'Beenverified Blog',
        href: 'https://www.beenverified.com/blog/',
        imgLogoUrl: beenverifiedLogo,
        icon: <BVLogo fontSize="medium" color="primary" />,
        hideOnSide: true,
        hideinNeedAssistanceNewDesign: true,
      },
    ],
    searchTabs: [
      {
        displayName: 'People',
        id: 'peopleTab',
        icon: (
          <Person
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        class: 'noBorderBottom noBorderRight',
        isLocked: true,
        isHidden: true,
        internalTabs: [
          {
            label: 'Search By Name',
            mobileLabel: 'Name',
            libFormComponentName: 'SearchPersonForm',
            eventCategory: 'People Search',
          },
          {
            label: 'Search By Address',
            mobileLabel: 'Address',
            libFormComponentName: 'SearchPersonByAddressForm',
            eventCategory: 'Person search by address',
          },
          {
            label: 'Search By Job Title',
            mobileLabel: 'Job Title',
            libFormComponentName: 'SearchContactForm',
            customTrackEvent: {
              category: 'contact search',
              action: 'navigation click',
              label: 'search',
            },
          },
        ],
      },
      {
        displayName: 'Phone',
        id: 'phoneTab',
        icon: (
          <Phone
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        class: 'noBorderBottom noBorderRight',
        isLocked: true,
        isHidden: true,
        internalTabs: [
          {
            label: 'Search By Phone Number',
            mobileLabel: 'Phone Number',
            libFormComponentName: 'SearchPhoneForm',
            eventCategory: 'Phone Search',
          },
        ],
      },
      {
        displayName: 'Property',
        id: 'propertyTab',
        icon: (
          <Home
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        class: 'noBorderBottom',
        isLocked: true,
        isHidden: true,
        internalTabs: [
          {
            label: 'Address Report',
            mobileLabel: 'Report',
            libFormComponentName: 'SearchPropertyForm',
            eventCategory: 'property',
          },
          {
            label: 'Pre-foreclosure',
            mobileLabel: 'Pre-foreclosure',
            libFormComponentName: 'SearchPFCPropertiesForm',
            eventCategory: 'Pre-Foreclosure',
          },
        ],
      },
      {
        displayName: 'Email',
        id: 'emailTab',
        icon: (
          <Email
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        class: 'noBorderRight',
        isLocked: true,
        isHidden: true,
        internalTabs: [
          {
            label: 'Search By Email',
            mobileLabel: 'Email',
            libFormComponentName: 'SearchEmailForm',
            eventCategory: 'Email Search',
          },
        ],
      },
      {
        displayName: 'Username',
        id: 'usernameTab',
        icon: (
          <AlternateEmail
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        class: 'noBorderRight',
        isLocked: true,
        isHidden: true,
        internalTabs: [
          {
            label: 'Search By Username',
            mobileLabel: 'Username',
            libFormComponentName: 'SearchUsernameForm',
            eventCategory: 'Username Search',
          },
        ],
      },
      {
        displayName: 'Vehicle',
        id: 'vehicleTab',
        icon: (
          <DirectionsCar
            fontSize="small"
            sx={{ color: { xs: '#0052FF', md: '#0A0B0D' } }}
          />
        ),
        internalTabs: [
          {
            label: 'VIN',
            mobileLabel: 'VIN',
            libFormComponentName: 'VINSearchForm',
          },
          {
            label: 'License Plate',
            mobileLabel: 'License Plate',
            libFormComponentName: 'LicensePlateSearchForm',
          },
          {
            label: 'Year, Make, Model',
            mobileLabel: 'Year, Make, Model',
            libFormComponentName: 'YMMSearchForm',
          },
        ],
      },
    ],
    sideMenuItems: [
      {
        route: '/dashboard',
        icon: <Home sx={{ color: '#0052FF' }} />,
        text: 'My Dashboard',
        name: 'dashboard',
        state: '',
        'data-cy': 'dashboard',
        ga4EventType: 'dashboard',
      },
      {
        route: '/dashboard/reports',
        icon: <Restore sx={{ color: '#0052FF' }} />,
        text: 'Recent Reports',
        name: 'recentReports',
        state: '',
        'data-cy': 'recent_reports',
        ga4EventType: 'recent_reports',
      },
      {
        route: '/dashboard/lists',
        icon: <List sx={{ color: '#0052FF' }} />,
        text: 'Saved Reports and Lists',
        state: '',
        name: 'lists',
        'data-cy': 'saved_reports_and_list',
        ga4EventType: 'lists',
      },
      {
        route: '/dashboard/language',
        icon: <Public sx={{ color: '#0052FF' }} />,
        text: (
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            fontSize="1.125rem"
          >
            Language Tutor
            <BetaPill customStyle={{ marginLeft: 1.1, pl: 0.46, pr: 0.46 }} />
          </Stack>
        ),
        state: '',
        name: 'language',
        ga4EventType: 'language_tutor',
      },
      {
        route: '/dashboard/fraud',
        icon: <Radar sx={{ color: '#0052FF' }} />,
        text: (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            FraudScan
            <PillList items={[{ label: 'New' }]} />
          </Stack>
        ),
        state: '',
        name: 'fraudScan',
        ga4EventType: 'fraud_scan',
      },
      {
        route: '/dashboard/personality-traits',
        icon: <Psychology sx={{ color: '#0052FF' }} />,
        text: (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            Personality Traits
            <PillList items={[{ label: 'New' }]} />
          </Stack>
        ),
        state: '',
        name: 'personalityTraits',
        'data-cy': 'personality_traits',
        ga4EventType: 'personality_traits',
      },
      {
        route: '/dashboard/astrology',
        icon: <FlareRounded sx={{ color: '#0052FF' }} />,
        text: (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            Astrology
            <PillList items={[{ label: 'New' }]} />
          </Stack>
        ),
        state: '',
        name: 'astrology',
        ga4EventType: 'astrology',
      },
      {
        route: '/dashboard/family-ancestry',
        icon: <CustomIcons.FamilyTreeAncestryIcon sx={{ color: '#0052FF' }} />,
        text: 'Family & Ancestry',
        state: '',
        name: 'family&ancestry',
        ga4EventType: 'family_ancestry',
        childItems: [
          {
            route: '/family-tree',
            icon: <CustomIcons.FamilyTreeIcon sx={{ color: '#0052FF' }} />,
            text: 'Family Tree',
            state: '',
            name: 'familyTree',
            ga4EventType: 'family_tree',
          },
          {
            route: '/dashboard/obituary',
            icon: <LocalFlorist sx={{ color: '#0052FF' }} />,
            text: 'Obituary Search',
            state: '',
            name: 'obituarySearch',
            isLocked: true,
            ga4EventType: 'obituary',
          },
        ],
      },
      {
        route: '/dashboard/rewards',
        icon: <Redeem sx={{ color: '#0052FF' }} />,
        text: 'Member Perks',
        state: '',
        name: 'memberPerks',
        ga4EventType: 'member_perks',
      },
      {
        route: '/dashboard/monitoring',
        icon: <NotificationsNone sx={{ color: '#0052FF' }} />,
        text: 'Monitoring',
        state: '',
        name: 'monitoring',
        ga4EventType: 'monitoring',
      },
      {
        route: '/search/owner',
        icon: <PersonPinCircle sx={{ color: '#0052FF' }} />,
        text: 'Owner Search',
        state: '',
        name: 'owner_search',
        ga4EventType: 'owner_search',
      },
      {
        route: '/dashboard/my-garage',
        icon: <DirectionsCarRounded sx={{ color: '#0052FF' }} />,
        text: 'My Garage',
        state: '',
        name: 'myGarage',
        ga4EventType: 'my_garage',
      },
      {
        route: '/dashboard/id-monitor',
        icon: (
          <img
            src={IdMonitorBvLogo}
            style={{ height: '27px', marginRight: '8px' }}
          />
        ),
        text: 'ID Monitor',
        state: '',
        name: 'idMonitor',
        isLocked: true,
        ga4EventType: 'id_monitor',
        childItems: [
          {
            route: '/dashboard/dark-web',
            icon: <CustomIcons.DarkWebIcon color="primary" />,
            text: 'Dark Web',
            state: '',
            name: 'darkWebScan',
            'data-cy': 'dark_web_scan',
            isLocked: true,
            ga4EventType: 'dark_web',
          },
          {
            route: '/dashboard/unclaimed-money',
            icon: <AttachMoney sx={{ color: '#0052FF' }} />,
            text: 'Unclaimed Money',
            state: '',
            name: 'unclaimedMoney',
            'data-cy': 'unclaimed_money',
            isLocked: true,
            ga4EventType: 'unclaimed_money',
          },
        ],
      },

      {
        route: '/dashboard/neighborhood-report',
        icon: <MapRounded sx={{ color: '#0052FF' }} />,
        text: 'Neighborhood Report',
        state: '',
        name: 'neighborhoodReport',
        isLocked: true,
        ga4EventType: 'neighborhood_report',
      },
      {
        route: '/search/contact?advancedSearch=true&source=contactSearch',
        icon: <Work sx={{ color: '#0052FF' }} />,
        text: 'Contact Pro',
        state: '',
        name: 'contactSearch',
        isLocked: true,
        ga4EventType: 'contact_search',
      },
      {
        route: '/dashboard/billing',
        icon: <AccountCircle sx={{ color: '#0052FF' }} />,
        text: 'My Account',
        state: '',
        name: 'myAccount',
        'data-cy': 'my_account',
        ga4EventType: 'my_account',
      },

      {
        route: '/dashboard/referrals',
        icon: <PersonAdd sx={{ color: '#0052FF' }} />,
        text: 'Send Invite',
        state: '',
        name: 'referrals',
        ga4EventType: 'referrals',
      },
    ],
    reportTypeFilterOptions,
    higherConfidenceThreshold: 50,
    hardcodedPlans: [],
    useLLMPersonMaxRetries: 2,
  },
  keys: {
    rapidsAPIKey: '7ZfcCjTZKz4rRGUm6ny6',
    mapboxKey:
      'pk.eyJ1IjoiYmVlbnZlcmlmaWVkIiwiYSI6ImNqeXN3ejY2MDAwbXczaHFzcnp6cmo5eGUifQ.hEfUf5T6xAzgW8wghHulEA',
    mrRogersAPIKey: 'kl6x16zhIp39hszFhGt5cxAfDV1MoXId',
    streetViewStaticApiKey: 'AIzaSyB1wzcy6dXy1xh3eZ8QfjClmeVCEyaSLIo',
    topHapApiKey: 'si_MKritZu1BytMaH',
    astrologyUser: '628813',
    astrologyKey: 'de77e69408c28b37a0e40aaa1ac63c41',
  },
  variationPlanNames: [
    '7_day_trial_1_00_up_front_28_79_recurring_monthly_freshness_limit_100_vin_no_pdf_vehicle_only',
    '7_day_trial_5_00_up_front_28_79_recurring_monthly_freshness_limit_100_vin_free_pdf_vehicle_only',
  ],
  paymentPremiumModalCardData: {
    premiumPlan:
      '36_79_1_month_nofree_afill_limit_100_freepdf_upsell_alldataaccess',
    premiumPlanAmount: '$36.79',
    premiumPlanReports: [
      'Person Reports',
      'Property Reports',
      'Username, Phone & Email Reports',
      'Dark Web Scan',
    ],
    currentPlanReports: [
      'VIN & License Plate Lookup',
      'Year, Make, Model Search',
    ],
    premiumMessage:
      'to unlock additional search options of names, phones, addresses & more!',
  },
  downsellModalCardData: {
    downsellPlan: '14_99_1_month_nofree_afill_limit25reports_freepdf_downsell',
    downsellPlanAmount: '14.99',
  },
  internationalPlanNames: {
    vinPlan: [
      // BV Vehicle international plans
      '7_day_trial_1_00_up_front_22_99_recurring_monthly_limit_50_no_pdf_vehicle_international',
      '7_day_trial_5_00_up_front_22_99_recurring_monthly_limit_free_pdf_vehicle_international',
      '22_99_1_month_limit_50_freepdf_vehicle_international',
      '44_83_3_month_limit_50_freepdf_vehicle_international',
      'one_check_29_99_60day_renewal_limit_1_bv_international',
      // BV Vehicle international reactivation plans
      '22_99_1_month_limit_50_freepdf_vehicle_international_reactivation',
      '44_83_3_month_limit_50_freepdf_vehicle_international_reactivation',
    ],

    languagePlan: [
      // BV Language international plans
      '7_day_trial_1_00_up_front_9_99_recurring_monthly_limit_0_no_pdf_language_international',
      '9_99_1_month_limit_0_nopdf_language_international',
      '19_47_3_month_limit_0_nopdf_language_international',
      // BV Language international reactivation plans
      '9_99_1_month_limit_0_nopdf_language_international_reactivation',
      '19_47_3_month_limit_0_nopdf_language_international_reactivation',
    ],
  },
  migrationPlanNames: [
    '9_95_1_month_nofree_afill_limit100reports_freepdf_plan_migration',
    '22_86_1_month_nofree_afill_limit100reports_freepdf_plan_migration_v3',
  ],
  personalityTraitsColors: {
    radioButtonColors: ['#DC0015', '#EA6672', '#808080', '#90d393', '#388E3C'],
    radioButtonHoverColors: [
      '#f9d0d3',
      '#fbdfe1',
      '#d8d8d8',
      '#e4f4e5',
      '#bae4bc',
    ],
  },
};

export const sideMenuItemsForNewDesign = (
  isInRenameSidebarItemsVariation: boolean,
  isInAstrologyStandaloneVariation: boolean
) => {
  const items: SideMenuItem[] = [
    {
      route: '/dashboard',
      icon: <Home sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'My Dashboard',
      name: 'dashboard',
      state: '',
      'data-cy': 'dashboard',
    },
    {
      route: '',
      icon: <Summarize sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'Reports',
      name: 'reports',
      state: '',
      'data-cy': 'reports',
      childItems: [
        {
          route: '/dashboard/reports',
          icon: <Restore sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Recent Reports',
          name: 'recentReports',
          state: '',
          'data-cy': 'recent_reports',
        },
        {
          route: '/dashboard/lists',
          icon: (
            <List
              color="secondary"
              sx={{ fontSize: '24px', color: '#0052FF' }}
            />
          ),
          text: 'Saved Reports and Lists',
          state: '',
          name: 'lists',
          'data-cy': 'saved_reports_and_list',
        },
        {
          route: '/dashboard/my-garage',
          icon: (
            <DirectionsCarRounded sx={{ fontSize: '24px', color: '#0052FF' }} />
          ),
          text: 'My Garage',
          state: '',
          name: 'myGarage',
        },
      ],
    },
    {
      route: '',
      icon: <Search sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'More Searches',
      state: '',
      name: 'more-searches',
      childItems: [
        {
          route: '/search/contact?advancedSearch=true&source=contactSearch',
          icon: <Work sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Contact Pro',
          state: '',
          name: 'contactSearch',
          isLocked: true,
        },
        {
          route: '/dashboard/unclaimed-money',
          icon: <AttachMoney sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Unclaimed Money',
          state: '',
          name: 'unclaimedMoney',
          'data-cy': 'unclaimed_money',
          isLocked: true,
        },
        {
          route: '/dashboard/fraud',
          icon: <Radar color="primary" />,
          text: (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              FraudScan
              <PillList items={[{ label: 'New' }]} />
            </Stack>
          ),
          state: '',
          name: 'fraudScan',
        },
      ],
    },
    {
      route: '',
      icon: <Lightbulb sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'Personal Growth',
      name: 'personal-growth',
      state: '',
      'data-cy': 'personal-growth',
      childItems: [
        {
          route: '/dashboard/language',
          icon: <Public sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: (
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              fontSize="1.125rem"
            >
              Language Tutor
              <BetaPill customStyle={{ marginLeft: 1.1, pl: 0.46, pr: 0.46 }} />
            </Stack>
          ),
          state: '',
          name: 'language',
        },
        {
          route: '/dashboard/personality-traits',
          icon: <Psychology sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: (
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              fontSize="1.125rem"
            >
              Personality Traits
              <PillList items={[{ label: 'New' }]} />
            </Stack>
          ),
          state: '',
          name: 'personalityTraits',
          'data-cy': 'personality_traits',
        },
      ],
    },
    {
      route: '/dashboard/family-ancestry',
      icon: <FamilyRestroom sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'Family & Ancestry',
      state: '',
      name: 'family&ancestry',
      childItems: [
        {
          route: '/dashboard/family-ancestry',
          icon: (
            <CustomIcons.FamilyTreeAncestryIcon
              color="primary"
              sx={{ fontSize: '24px' }}
            />
          ),
          text: 'Family Dashboard',
          state: '',
          name: 'family-dashboard',
        },
        {
          route: '/family-tree',
          icon: (
            <CustomIcons.FamilyTreeIcon
              color="primary"
              sx={{ fontSize: '24px' }}
            />
          ),
          text: 'Family Tree',
          state: '',
          name: 'familyTree',
        },
        {
          route: '/dashboard/obituary',
          icon: <LocalFlorist sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Obituary Search',
          state: '',
          name: 'obituarySearch',
          isLocked: true,
        },
      ],
    },
    {
      route: '',
      icon: <NotificationsActive sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'Monitoring Hub',
      state: '',
      name: 'monitoring-hub',
      childItems: [
        {
          route: '/dashboard/monitoring',
          icon: <NotificationsNone color="primary" sx={{ fontSize: '24px' }} />,
          text: 'Monitoring',
          state: '',
          name: 'monitoring',
        },
        {
          route: '/dashboard/id-monitor',
          icon: (
            <CustomIcons.IdMonitorIcon
              color="primary"
              sx={{ fontSize: '24px' }}
            />
          ),
          text: 'ID Monitor',
          state: '',
          name: 'idMonitor',
          isLocked: true,
        },
        {
          route: '/dashboard/dark-web',
          icon: (
            <CustomIcons.DarkWebIcon
              color="primary"
              sx={{ fontSize: '24px' }}
            />
          ),
          text: 'Dark Web',
          state: '',
          name: 'darkWebScan',
          'data-cy': 'dark_web_scan',
          isLocked: true,
        },
      ],
    },
    {
      route: '',
      icon: <ManageAccounts sx={{ fontSize: '24px', color: '#0052FF' }} />,
      text: 'My Profile',
      state: '',
      name: 'my-profile',
      childItems: [
        {
          route: '/dashboard/billing',
          icon: <AccountCircle sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'My Account',
          state: '',
          name: 'myAccount',
          'data-cy': 'my_account',
        },
        {
          route: '/dashboard/rewards',
          icon: <Redeem sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Member Perks',
          state: '',
          name: 'memberPerks',
        },
        {
          route: '/dashboard/referrals',
          icon: <PersonAdd sx={{ fontSize: '24px', color: '#0052FF' }} />,
          text: 'Send Invite',
          state: '',
          name: 'referrals',
        },
      ],
    },
  ];

  if (isInRenameSidebarItemsVariation) {
    const reportsItem = items.find((i) => i.name === 'reports');

    const ommitedReportItems = new Set(['myGarage']);
    const omittedItems = new Set<string>(['personal-growth', 'monitoring-hub']);

    const reportChildItems = reportsItem?.childItems?.filter(
      (i) => !ommitedReportItems.has(i.name)
    );

    reportsItem!.childItems = [
      // @ts-ignore
      ...reportChildItems,
      {
        route: '/dashboard/my-reports',
        icon: (
          <CustomIcons.IdMonitorIcon
            color="primary"
            sx={{ fontSize: '24px' }}
          />
        ),
        text: 'My Reports',
        state: '',
        name: 'myReports',
        isLocked: true,
      },
    ];

    return items.filter((option) => !omittedItems.has(option.name));
  }

  items
    .find((i) => i.name === 'more-searches')
    ?.childItems?.push({
      route: '/dashboard/neighborhood-report',
      icon: <MapRounded sx={{ fontSize: '24px', color: '#0052FF' }} />,
      // @ts-ignore
      text: 'Neighborhood Search',
      state: '',
      name: 'neighborhoodSearch',
      isLocked: true,
    });

  if (isInAstrologyStandaloneVariation) {
    const personalGrowthIndex = items.findIndex(
      (i) => i.name === 'personal-growth'
    );
    if (personalGrowthIndex !== -1) {
      const personalGrowthItem = items[personalGrowthIndex];
      personalGrowthItem?.childItems?.push({
        route: '/dashboard/astrology',
        icon: <FlareRounded color="primary" />,
        text: (
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            fontSize="1.125rem"
          >
            Astrology
            <PillList items={[{ label: 'New' }]} />
          </Stack>
        ),
        state: '',
        name: 'astrology',
        'data-cy': 'astrology',
      });
    }
  }
  return items;
};

export const internationalSideMenuItems = [
  {
    route: '',
    icon: <Summarize sx={{ fontSize: '24px', color: '#0052FF' }} />,
    text: 'Reports',
    name: 'reports',
    state: '',
    'data-cy': 'reports',
    childItems: [
      {
        route: '/dashboard/reports',
        icon: <Restore sx={{ fontSize: '24px', color: '#0052FF' }} />,
        text: 'Recent Reports',
        name: 'recentReports',
        state: '',
        'data-cy': 'recent_reports',
      },
      {
        route: '/dashboard/lists',
        icon: <List sx={{ fontSize: '24px', color: '#0052FF' }} />,
        text: 'Saved Reports and Lists',
        state: '',
        name: 'lists',
        'data-cy': 'saved_reports_and_list',
      },
      {
        route: '/dashboard/my-garage',
        icon: (
          <DirectionsCarRounded color="primary" sx={{ fontSize: '24px' }} />
        ),
        text: 'My Garage',
        state: '',
        name: 'myGarage',
      },
    ],
  },
  {
    route: '',
    icon: <Lightbulb sx={{ fontSize: '24px', color: '#0052FF' }} />,
    text: 'Personal Growth',
    name: 'personal-growth',
    state: '',
    'data-cy': 'personal-growth',
    childItems: [
      {
        route: '/dashboard/language',
        icon: <Public sx={{ fontSize: '24px', color: '#0052FF' }} />,
        text: (
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            fontSize="1.125rem"
          >
            Language Tutor
            <BetaPill customStyle={{ marginLeft: 1.1, pl: 0.46, pr: 0.46 }} />
          </Stack>
        ),
        state: '',
        name: 'language',
      },
    ],
  },
  {
    route: '',
    icon: <NotificationsActive sx={{ fontSize: '24px', color: '#0052FF' }} />,
    text: 'Monitoring Hub',
    state: '',
    name: 'monitoring-hub',
    childItems: [
      {
        route: '/dashboard/monitoring',
        icon: <NotificationsNone sx={{ fontSize: '24px', color: '#0052FF' }} />,
        text: 'Monitoring',
        state: '',
        name: 'monitoring',
      },
    ],
  },
  {
    route: '',
    icon: <ManageAccounts sx={{ fontSize: '24px', color: '#0052FF' }} />,
    text: 'My Profile',
    state: '',
    name: 'my-profile',
    childItems: [
      {
        route: '/dashboard/billing',
        icon: <AccountCircle sx={{ fontSize: '24px', color: '#0052FF' }} />,
        text: 'My Account',
        state: '',
        name: 'myAccount',
        'data-cy': 'my_account',
      },
    ],
  },
];
