import {
  AIPill,
  NavLinkData,
  type SerializedVehicleReport,
} from '@ltvco/refresh-lib/v1';
import { ReportMenuItem } from '@ltvco/refresh-lib/v2';
import {
  ConstructionOutlined,
  DirectionsCarOutlined,
  FeedOutlined,
  HistoryOutlined,
  InfoOutlined,
  Lock,
  StorefrontOutlined,
  SummarizeOutlined,
} from '@mui/icons-material';

export const getVehicleNavLinkDataV2 = (
  reportData: SerializedVehicleReport | null,
  complaintsCount: number
) => {
  const automobile = reportData?.automobiles[0];

  if (!reportData) return [];

  const isYMMSearch = reportData.meta?.search_by === 'ymm';

  const safetyCount =
    reportData?.automobiles[0]?.safety_ratings?.length + complaintsCount;
  const ymmPresent = [
    automobile?.year,
    automobile?.make,
    automobile?.model,
  ].filter(Boolean);
  const hasAllYMMPresent = ymmPresent.length === 3;
  const shouldShowSmartInsightsNavLink = !isYMMSearch && hasAllYMMPresent;

  const navLinkData: ReportMenuItem[] = [
    {
      href: '#vehicle-report-overview',
      sectionId: '#vehicle-overview',
      labelText: 'Photos',
      icons: [
        <DirectionsCarOutlined sx={{ color: '#0052FF', fontSize: '2.5rem' }} />,
      ],
      reportItemsCount: automobile?.imagesCount || null,
      hideReportItemsCount: false,
      notificationSection: 'identity',
      visible: true,
    },
    {
      labelText: 'Overview',
      name: 'overview',
      icons: [
        <SummarizeOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />,
      ],
      visible: true,
      childItems: [
        {
          href: '#vehicle-report-overview',
          sectionId: '#vehicle-overview',
          labelText: 'Photos',
          name: 'vehicle-photos',
          reportItemsCount: automobile?.imagesCount || null,
          hideReportItemsCount: false,
          visible: true,
          notificationSection: 'identity',
        },
        {
          href: '#smart-insights',
          sectionId: '#smart-insights',
          labelText: 'Smart Insights',
          name: 'vehicle-smart-insights',
          visible: shouldShowSmartInsightsNavLink,
          reportItemsCount: 0,
          notificationSection: 'smart insights',
          icons: [<AIPill />],
          showIcon: true,
        },
      ],
    },
    {
      labelText: 'Vehicle History',
      name: 'vehicle-history',
      icons: [
        <HistoryOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />,
      ],
      visible: !isYMMSearch,
      childItems: [
        {
          href: '#vehicle-ownership-history',
          sectionId: 'vehicle-ownership-history',
          labelText: 'Ownership History',
          name: 'vehicle-ownership-history',
          reportItemsCount: automobile?.titleRecordsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
          icons: [
            <Lock
              fontSize="small"
              sx={{
                marginLeft: '10px',
                color: '#212121',
              }}
            />,
          ],
          showIcon:
            !automobile?.nmvtis_success && automobile?.titleRecordsCount === 0,
        },
        {
          href: '#vehicle-title-records',
          sectionId: 'vehicle-title-records',
          labelText: 'Title Records',
          name: 'vehicle-title-records',
          reportItemsCount: automobile?.titleRegistrationsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
          icons: [
            <Lock
              fontSize="small"
              sx={{
                marginLeft: '10px',
                color: '#212121',
              }}
            />,
          ],
          showIcon:
            !automobile?.nmvtis_success &&
            automobile?.titleRegistrationsCount === 0,
        },
        {
          href: '#vehicle-title-brands',
          sectionId: '#vehicle-title-brands',
          labelText: 'Title Brands',
          name: 'vehicle-title-brands',
          reportItemsCount: automobile?.titleChecksCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
          icons: [
            <Lock
              fontSize="small"
              sx={{
                marginLeft: '10px',
                color: '#212121',
              }}
            />,
          ],
          showIcon:
            !automobile?.nmvtis_success && automobile?.titleChecksCount === 0,
        },
        {
          href: '#vehicle-liens',
          sectionId: 'vehicle-liens',
          labelText: 'Liens',
          name: 'vehicle-liens',
          reportItemsCount: automobile?.liensCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-impound',
          sectionId: '#vehicle-impound',
          labelText: 'Impound',
          name: 'vehicle-impound',
          reportItemsCount: automobile?.impoundsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-exports',
          sectionId: '#vehicle-exports',
          labelText: 'Exports',
          name: 'vehicle-exports',
          reportItemsCount: automobile?.exportsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-thefts',
          sectionId: '#vehicle-thefts',
          labelText: 'Theft Records',
          name: 'vehicle-thefts',
          reportItemsCount: automobile?.theftsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-damage-history',
          sectionId: '#vehicle-damage-history',
          labelText: 'Damage History',
          name: 'vehicle-damage-history',
          reportItemsCount: automobile?.damageHistoryCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
          icons: [
            <Lock
              fontSize="small"
              sx={{
                marginLeft: '10px',
                color: '#212121',
              }}
            />,
          ],
          showIcon:
            !automobile?.nmvtis_success && automobile?.damageHistoryCount === 0,
        },
      ],
    },
    {
      labelText: 'Market Value',
      name: 'market-value',
      icons: [
        <StorefrontOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />,
      ],
      visible: true,
      childItems: [
        {
          href: '#vehicle-market',
          sectionId: '#vehicle-market',
          labelText: 'Market Value',
          name: 'vehicle-market',
          reportItemsCount: automobile?.marketValueCount,
          hideReportItemsCount: false,
          visible: true,
        },
        {
          href: '#vehicle-ownership-cost',
          sectionId: 'vehicle-ownership-cost',
          labelText: 'Ownership Costs',
          name: 'vehicle-ownership-cost',
          reportItemsCount: automobile?.costsCount,
          hideReportItemsCount: false,
          visible: true,
        },
        {
          href: '#vehicle-sales',
          sectionId: '#vehicle-sales',
          labelText: 'Sale Listings',
          name: 'vehicle-sales',
          reportItemsCount: automobile?.listingsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-auctions',
          sectionId: '#vehicle-auctions',
          labelText: 'Auctions',
          name: 'vehicle-auctions',
          reportItemsCount: automobile?.auctionsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
      ],
    },
    {
      labelText: 'Maintenance & Repairs',
      name: 'maintenance-repairs',
      icons: [
        <ConstructionOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />,
      ],
      visible: true,
      childItems: [
        {
          href: '#vehicle-service-and-repairs',
          sectionId: '#vehicle-service-and-repairs',
          name: 'vehicle-service-and-repairs',
          labelText: 'Service & Repairs',
          reportItemsCount: automobile?.repairsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
          notificationSection: 'maintenance',
        },
        {
          href: '#vehicle-inspections',
          sectionId: '#vehicle-inspections',
          name: 'vehicle-inspections',
          labelText: 'Inspections',
          reportItemsCount: automobile?.inspectionEventsCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-maintenance-schedule',
          sectionId: '#vehicle-maintenance-schedule',
          name: 'vehicle-maintenance-schedule',
          labelText: 'Maintenance Schedule',
          reportItemsCount: automobile?.maintenanceCount,
          hideReportItemsCount: false,
          visible: !isYMMSearch,
        },
        {
          href: '#vehicle-recalls',
          sectionId: '#vehicle-recalls',
          name: 'vehicle-recalls',
          labelText: 'Recalls',
          reportItemsCount: isYMMSearch
            ? automobile?.recallsCount
            : automobile?.recallsWithStatusCount,
          hideReportItemsCount: false,
          visible: true,
          notificationSection: 'prices',
        },
        {
          href: '#detailed-history',
          sectionId: '#detailed-history',
          name: 'vehicle-detailed-history',
          labelText: 'Detailed History',
          reportItemsCount: automobile?.detailedHistoryCount,
          hideReportItemsCount: false,
          visible: Boolean(!isYMMSearch && automobile?.detailedHistoryCount),
          notificationSection: 'history',
        },
      ],
    },
    {
      labelText: 'Vehicle Specs',
      name: 'vehicle-specs',
      icons: [<FeedOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />],
      visible: true,
      childItems: [
        {
          href: '#vehicle-specs',
          sectionId: '#vehicle-specs',
          name: 'vehicle-specs',
          labelText: 'Specs',
          reportItemsCount: automobile?.specsCount,
          hideReportItemsCount: false,
          notificationSection: 'specifications',
          visible: true,
        },
      ],
    },
    {
      labelText: 'Additional Info',
      name: 'additional-info',
      icons: [<InfoOutlined sx={{ color: '#0052FF', fontSize: '1.4rem' }} />],
      visible: true,
      childItems: [
        {
          href: '#vehicle-warranty',
          sectionId: '#vehicle-warranty',
          name: 'vehicle-warranty',
          labelText: 'Warranties',
          reportItemsCount: automobile?.warrantiesCount,
          hideReportItemsCount: false,
          visible: true,
          notificationSection: 'warranties',
        },
        {
          href: '#vehicle-safety-ratings',
          sectionId: '#vehicle-safety-ratings',
          name: 'vehicle-safety-ratings',
          labelText: 'Vehicle Safety',
          reportItemsCount: safetyCount,
          hideReportItemsCount: false,
          visible: true,
          notificationSection: 'safety',
        },
      ],
    },
  ];

  return navLinkData
    .map((item) => ({
      ...item,
      childItems: item.childItems
        ? item.childItems.filter((child) => child.visible)
        : [],
    }))
    .filter((item) => item.visible || item.childItems.length > 0);
};

export const getVehicleNavLinkData = (
  reportData: SerializedVehicleReport | null,
  complaintsCount: number
) => {
  const automobile = reportData?.automobiles[0];

  if (!reportData) return [];

  const isYMMSearch = reportData.meta?.search_by === 'ymm';

  const safetyCount =
    reportData?.automobiles[0]?.safety_ratings?.length + complaintsCount;
  const ymmPresent = [
    automobile?.year,
    automobile?.make,
    automobile?.model,
  ].filter(Boolean);
  const hasAllYMMPresent = ymmPresent.length === 3;
  const shouldShowSmartInsightsNavLink = !isYMMSearch && hasAllYMMPresent;

  const navLinkData: NavLinkData[] = [
    {
      href: '#vehicle-report-overview',
      sectionId: '#vehicle-overview',
      text: 'Photos',
      reportItemsCount: automobile?.imagesCount || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'identity',
      name: 'vehicle-photos',
    },
    {
      href: '#smart-insights',
      sectionId: '#smart-insights',
      text: 'Smart Insights',
      visible: shouldShowSmartInsightsNavLink,
      reportItemsCount: 0,
      notificationSection: 'smart insights',
      icon: <AIPill />,
      showIcon: true,
      name: 'smart-insights',
    },
    {
      href: '#detailed-history',
      sectionId: '#detailed-history',
      text: 'Detailed History',
      reportItemsCount: automobile?.detailedHistoryCount || null,
      hideReportItemsCount: false,
      visible: !!(!isYMMSearch && automobile?.detailedHistoryCount),
      notificationSection: 'history',
      name: 'detailed-history',
    },
    {
      href: '#vehicle-sales',
      sectionId: '#vehicle-sales',
      text: 'Sale Listings',
      reportItemsCount: automobile?.listingsCount || null,
      hideReportItemsCount: false,
      visible: !isYMMSearch,
      notificationSection: 'sales',
      name: 'vehicle-sale-listings',
    },
    {
      href: '#vehicle-damage-history',
      sectionId: '#vehicle-damage-history',
      text: 'Damage History',
      reportItemsCount: automobile?.damageHistoryCount || null,
      hideReportItemsCount: false,
      visible: !isYMMSearch,
      name: 'vehicle-damage-history',
      icon: <Lock fontSize="small" sx={{ marginRight: '-6px' }} />,
      showIcon:
        !automobile?.nmvtis_success && automobile?.damageHistoryCount === 0,
    },
    {
      href: '#vehicle-thefts',
      sectionId: '#vehicle-thefts',
      text: 'Theft Records',
      reportItemsCount: automobile?.theftsCount || null,
      hideReportItemsCount: false,
      visible: !isYMMSearch,
      notificationSection: 'thefts',
      name: 'vehicle-theft-records',
    },
    {
      href: '#vehicle-service-and-repairs',
      sectionId: '#vehicle-service-and-repairs',
      text: 'Maintenance',
      reportItemsCount: automobile?.servicesAndRepairsCount || null,
      hideReportItemsCount: false,
      visible: !isYMMSearch,
      notificationSection: 'maintenance',
      name: 'maintenance',
    },
    {
      href: '#vehicle-ownership-history',
      sectionId: 'vehicle-ownership-history',
      text: 'Ownership History',
      reportItemsCount: automobile?.titleRecordsCount || null,
      hideReportItemsCount: false,
      visible: !isYMMSearch,
      name: 'vehicle-ownership-history',
      icon: <Lock fontSize="small" sx={{ marginRight: '-6px' }} />,
      showIcon:
        !automobile?.nmvtis_success && automobile?.titleRecordsCount === 0,
    },
    {
      href: '#vehicle-recalls',
      sectionId: '#vehicle-recalls',
      text: 'Recalls',
      reportItemsCount:
        (isYMMSearch
          ? automobile?.recallsCount
          : automobile?.recallsWithStatusCount) || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'prices',
      name: 'vehicle-recalls',
    },
    {
      href: '#vehicle-market',
      sectionId: '#vehicle-market',
      text: 'Market Value',
      reportItemsCount: automobile?.marketValueCount || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'market',
      name: 'vehicle-market-value',
    },
    {
      href: '#vehicle-ownership-cost',
      sectionId: '#vehicle-ownership-cost',
      text: 'Ownership Cost',
      reportItemsCount: automobile?.costsCount || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'costs',
      name: 'vehicle-ownership-cost',
    },
    {
      href: '#vehicle-specs',
      sectionId: '#vehicle-specs',
      text: 'Vehicle Specs',
      reportItemsCount: automobile?.specsCount || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'specifications',
      name: 'vehicle-specs',
    },
    {
      href: '#vehicle-warranty',
      sectionId: '#vehicle-warranty',
      text: 'Warranties',
      reportItemsCount: automobile?.warrantiesCount || null,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'warranties',
      name: 'vehicle-warranties',
    },
    {
      href: '#vehicle-safety-ratings',
      sectionId: '#vehicle-safety-ratings',
      text: 'Vehicle Safety',
      reportItemsCount: safetyCount,
      hideReportItemsCount: false,
      visible: true,
      notificationSection: 'safety',
      name: 'vehicle-safety-ratings',
    },
  ];

  const moveNavLink = (
    navLinkArray: NavLinkData[],
    sectionIdToMove: string,
    sectionIdAfter: string,
    GAEventLabel: string
  ) => {
    const currentIndex = navLinkArray.findIndex(
      (item) => item.sectionId === sectionIdToMove
    );

    const newIndex = navLinkArray.findIndex(
      (item) => item.sectionId === sectionIdAfter
    );

    if (currentIndex !== -1 && newIndex !== -1) {
      const [movedNavLink] = navLinkArray.splice(currentIndex, 1);
      (movedNavLink as NavLinkData).GAEventLabel = GAEventLabel;
      navLinkArray.splice(newIndex + 1, 0, movedNavLink);
    }
  };

  moveNavLink(
    navLinkData,
    'vehicle-ownership-history',
    '#vehicle-overview',
    'ownership history NEW'
  );
  moveNavLink(
    navLinkData,
    '#vehicle-specs',
    'vehicle-ownership-history',
    'vehicle specifications NEW'
  );

  if (shouldShowSmartInsightsNavLink) {
    moveNavLink(
      navLinkData,
      '#smart-insights',
      '#vehicle-overview',
      'smart insights NEW'
    );
  }

  return navLinkData.filter((item) => item.visible);
};
