import {
  useNavigate,
  Navigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { initialize } from '@ltv-apm-modules/react';
import Analytics from 'analytics';
import { useQueryParam, StringParam } from 'use-query-params';
import googleTagManager, {
  GoogleTagManagerConfig,
} from '@analytics/google-tag-manager';
import { version } from '@ltvco/refresh-lib';
import { ErrorBoundary, styled } from '@ltvco/refresh-lib/theme';
import {
  SearchFormProvider,
  Footer,
  BannerMigrationCta,
  StaypalChatbotModal,
  StaypalChatbotPersistentView,
} from '@ltvco/refresh-lib/v2';
import {
  CancelDownsellOfferModal,
  CancelFinalStepModal,
  CancellationReasonsModal,
  CancelUserExperienceModal,
  closeSnackbar,
  ComplianceFormPage,
  CompliancePermittedPage,
  ComplianceProhibitedPage,
  ComplianceRecomplyPage,
  ConfirmCancelModal,
  ContactOptionsModal,
  ForgotLoginModal,
  invalidateSession,
  isUserLoggedIn,
  MagicLinkModal,
  NonCompliancePage,
  ResetPasswordRequestForm,
  SearchData,
  SelfSearchingContainer,
  SessionProvider,
  SsoPromoBanner,
  TrustPilotModal,
  useSession,
} from '@ltvco/refresh-lib/v1';
import {
  FamilyAndAncestryDashboard,
  FamilyAlbum,
} from '@ltvco/refresh-lib/fmt';
import { TosModalLegalError } from '@ltvco/refresh-lib/payments';
import {
  DashboardParent,
  Login,
  Report,
  AuthenticatedRoute,
  UnclaimedMoney,
  DarkWebScan,
  Billing,
  Lists,
  SearchPersonResultsContainerv2,
  SearchListingResults,
  ResetPassword,
  Reactivation,
  PublicRoute,
  NotFound,
  ErrorPage,
  UpgradePlanPage,
  ReportVelocityCaptcha,
  Monitoring,
  NeighborhoodReportSection,
  RewardsPage,
  MyGarage,
  IdMonitor,
  CreditBureauContacts,
  AlertDetails,
  ObituarySearchPage,
  SearchObituaryResults,
  Referrals,
  FamilyTreePage,
  PersonalityTraitsPage,
  Fraud,
  AstrologyPage,
  RecentReports,
  DashboardPage,
} from 'routes';
import { constants } from 'appConstants';
import 'App.css';
import { Recycling } from 'components/recycling/Recycling';
import QueryWrapper from 'components/queryWrapper/QueryWrapper';
import { Growthbook } from 'components/Growthbook';
import { EmailVerification } from 'components/EmailVerification';
import { useEffect, useRef, useState } from 'react';
import packageInfo from '../package.json';
import { HeaderParent } from 'components/HeaderParent/HeaderParent';
import { LanguageDashboard } from 'components/Language/Dashboard/LanguageDashboard';
import { ChatContainer } from 'components/Language/ChatContainer/ChatContainer';
import { LanguageProvider } from 'components/Language/LanguageContext';
import { LessonChatContainer } from 'components/Language/LessonChatContainer/LessonChatContainer';
import { QuizChatContainer } from 'components/Language/QuizChatContainer/QuizChatContainer';
import { StoryChatContainer } from 'components/Language/StoryChatContainer/StoryChatContainer';
import { BaseNumerologyQueryKeys } from 'components/Astrology/interfaces';
import { goToLogin } from 'utils/goToLogin';
import { OwnerSearchParent } from 'routes/Dashboard/OwnerSearch/OwnerSearchParent';
import { AppContainer } from 'components/AppContainer';
import { generateFooterLinks } from 'utils/footerLinks/footerLinks';
import { LibContextWrapper } from 'components/LibContextWrapper';
import { MyReports } from 'routes/Dashboard/MyReports';
import { MobileDownloadGlobalBanner } from 'components/MobileDownloadGlobalBanner/MobileDownloadGlobalBanner';
import {
  ErrorRequestData,
  ResponseError,
  useScreenSize,
} from '@ltvco/refresh-lib/utils';

let otel: any = null;

if (
  import.meta.env.VITE_TARGET_ENV === 'production' ||
  import.meta.env.VITE_TARGET_ENV === 'staging'
) {
  otel = initialize({
    name: 'refresh-beenverified',
    url: import.meta.env.VITE_APM_URL,
    apikey: import.meta.env.VITE_APM_KEY,
  });
}

declare global {
  interface Window {
    env: Record<string, string>;
  }
}

const HeaderContainer = styled('div')`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`;

const SsoBannerContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const AppFooter = styled('div')(({ theme }) => ({
  maxWidth: '1400px',
  margin: theme.spacing(0),
  padding: theme.spacing(0, 2, 4),
  position: 'relative',
  backgroundColor: theme.palette.background.paper,

  [theme.breakpoints.up('xs')]: {
    margin: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 2, 4, 9),
  },
}));

const LibVersion = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 0 0`,
  fontSize: theme.typography.fontSize * 0.75,
}));

function removeSensitiveLocalStorageData() {
  localStorage.removeItem('astrology_user');
  localStorage.removeItem(BaseNumerologyQueryKeys.lifepath);
  localStorage.removeItem(BaseNumerologyQueryKeys.personality);
}

function App() {
  const analytics = Analytics({
    app: 'refresh-beenverified',
    debug: true,
    plugins: [
      googleTagManager({
        containerId: 'GTM-HV76',
        dataLayer: (window as any)?.dataLayer ?? [],
      } as GoogleTagManagerConfig),
    ],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSession();
  const { isMobile } = useScreenSize();
  const [isCoreLoginRedirect] = useQueryParam(
    'is_core_login_redirect',
    StringParam
  );
  const [loginType] = useQueryParam('login_type', StringParam);
  const [isAuthenticated, setIsAuthenticated] = useState(isUserLoggedIn());
  const [isContactOptionsModalOpen, setIsContactOptionsModalOpen] =
    useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isCancelFinalStepModalOpen, setIsCancelFinalStepModalOpen] =
    useState(false);
  const [isCancellationReasonsModalOpen, setIsCancellationReasonsModalOpen] =
    useState(false);
  const [isDownsellOfferModalOpen, setIsDownsellOfferModalOpen] =
    useState(false);
  const [isUserExperienceSurveyModalOpen, setIsUserExperienceSurveyModalOpen] =
    useState(false);
  const [isTrustPilotModalOpen, setIsTrustPilotModalOpen] = useState(false);
  const [isStaypalChatbotModalOpen, setIsStaypalChatbotModalOpen] =
    useState(false);
  const [isInSingleReportVariation, setIsInSingleReportVariation] =
    useState(false);
  const [isInInternationalLanguageTest, setIsInInternationalLanguageTest] =
    useState(false);
  const [isInInternationalVinTest, setIsInInternationalVinTest] =
    useState(false);
  const [isInSearchpalVariation, setIsInSearchpalVariation] = useState(false);
  const [isInStaypalChatbotVariation, setIsInStaypalChatbotVariation] =
    useState(false);
  const [showCheaperPlanLink, setShowCheaperPlanLink] = useState(false);
  const [tosState, setTosState] = useState<{
    modalOpen: boolean;
    requestData: ErrorRequestData['body'] | null;
  }>({
    modalOpen: false,
    requestData: null,
  });

  const [isInOwnerSearchVariation, setIsInOwnerSearchVariation] =
    useState(false);
  const [
    isInAstrologyStandaloneVariation,
    setIsInAstrologyStandaloneVariation,
  ] = useState(false);
  const [isInRenameSidebarItemsVariation, setIsInRenameSidebarItemsVariation] =
    useState(false);

  const loginPageContainerRef = useRef(null);
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (
      isCoreLoginRedirect === 'true' &&
      !sessionStorage.getItem('has_tracked_core_login_redirect')
    ) {
      analytics.track('site_event', {
        event_name: 'login_attempt',
        type: loginType || 'web',
        success: true,
      });

      sessionStorage.setItem(
        'has_tracked_core_login_redirect',
        isCoreLoginRedirect
      );
    }
  }, [isCoreLoginRedirect]);

  const changeIsInInternationalLanguageTest = (variation: boolean) => {
    setIsInInternationalLanguageTest(variation);
  };

  const changeIsInInternationalVinTest = (variation: boolean) => {
    setIsInInternationalVinTest(variation);
  };

  const changeIsInSearchpalVariation = (variation: boolean) => {
    setIsInSearchpalVariation(variation);
  };

  const changeIsInStaypalChatbotVariation = (variation: boolean) => {
    setIsInStaypalChatbotVariation(variation);
  };

  const changeIsInSingleReportVariation = (variation: boolean) => {
    setIsInSingleReportVariation(variation);
  };

  const changeIsInOwnerSearchVariation = (variation: boolean) => {
    setIsInOwnerSearchVariation(variation);
  };

  const changeIsInAstrologyStandaloneVariation = (variation: boolean) => {
    setIsInAstrologyStandaloneVariation(variation);
  };

  const changeIsInRenameSidebarItemsVariation = (variation: boolean) => {
    setIsInRenameSidebarItemsVariation(variation);
  };

  async function handleLogoutClick(ga4Ttracker?: () => void) {
    await invalidateSession();
    setIsAuthenticated(isUserLoggedIn());
    closeSnackbar();
    removeSensitiveLocalStorageData();
    goToLogin('https://www.beenverified.com');
    if (ga4Ttracker) {
      ga4Ttracker();
    }
  }

  function isResponseError(error: any): error is ResponseError {
    return error.response !== undefined;
  }

  function logError(
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) {
    const isProdEnv = import.meta.env.VITE_TARGET_ENV === 'production';
    if (!isProdEnv || !otel) return;

    const { account } = session;
    const { api } = otel;
    if (api.isOTELInitialized()) {
      if (account) {
        api.setUser({
          id: account?.account.user_info.user_code,
          email: account?.account.user_info.email,
        });
      }
      let errorMsg = `${context}: ${error.message}, Cause: ${error.cause}`;
      if (isResponseError(error)) {
        errorMsg = `${errorMsg}, Status: ${
          error.response.status
        }, Data: ${JSON.stringify(error.response)}`;
      }
      if (url) {
        errorMsg += `, URL: ${url}`;
      }
      api.pushError(new Error(errorMsg));
    }
  }

  function trackEventGA4(eventToTrack: object, eventName?: string) {
    const targetEnv = import.meta.env.VITE_TARGET_ENV;
    if (targetEnv === 'dev') return;
    analytics?.track(eventName || 'site_event', eventToTrack);
  }

  function trackEvent(category: string, action: string, label?: string) {
    analytics.track('ua_event', {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  const onLoginSuccess = (
    isInInternationalLanguageTest: boolean,
    isInInternationalVinTest: boolean
  ) => {
    let next = '/';

    if (location.state && location.state.next) {
      next = location.state.next;
    }

    if (isInInternationalLanguageTest || isInInternationalVinTest) {
      isInInternationalVinTest
        ? navigate('/dashboard/reports')
        : navigate('/dashboard/language');
    } else {
      navigate(
        next === '/' ||
          (next.startsWith('/report') && !next.includes('permalink'))
          ? '/dashboard'
          : next
      );
    }

    setIsAuthenticated(isUserLoggedIn());
  };

  const contactUsHandler = () => {
    setIsContactOptionsModalOpen(true);
  };

  const handleSearchSuccess = ({
    reportType,
    searchParams,
    permalink,
  }: SearchData) => {
    const url = `/report/${reportType}?${searchParams}&permalink=${permalink}`;
    navigate(url);
  };

  const showFooter = !location.pathname.includes('self-searching');
  const [isSuccessModaVisible, setIsSuccessModaVisible] =
    useState<boolean>(false);

  const openSuccessModal = () => {
    setIsSuccessModaVisible(true);
  };

  const handleCancellationModalClose = (reason: string | undefined) => {
    setIsCancellationReasonsModalOpen(false);
    if (reason === 'Too expensive') {
      setIsDownsellOfferModalOpen(true);
    } else if (reason === 'Other' || reason === 'No longer needed') {
      setIsUserExperienceSurveyModalOpen(true);
    } else if (reason === "I couldn't find what I was looking for") {
      if (isInStaypalChatbotVariation) {
        setIsStaypalChatbotModalOpen(true);
      }
    }
  };

  const handleCloseUserExperienceSurveyModal = (
    rating: string | null | undefined
  ) => {
    setIsUserExperienceSurveyModalOpen(false);
    if (rating && parseInt(rating) >= 8) {
      setIsTrustPilotModalOpen(true);
    }
  };

  const closeStaypalModalandOpenPersistentView = () => {
    setIsStaypalChatbotModalOpen(false);
    localStorage.setItem('showStaypalModalPersistentView', 'true');
  };

  const openConfirmCancelModal = (downsellTest?: boolean) => {
    setIsConfirmCancelModalOpen(true);
    setShowCheaperPlanLink(downsellTest || false);
  };

  const onCancelConfirmCancelModal = () => {
    setIsConfirmCancelModalOpen(false);
    setIsCancellationReasonsModalOpen(true);
  };

  const showStaypalModalPersistentView = () =>
    (localStorage.getItem('showStaypalModalPersistentView') == 'true' ||
      isInSearchpalVariation) &&
    !location.pathname.includes('login');

  return (
    <ErrorBoundary
      redirectFunction={() => {
        navigate('/error');
      }}
      location={location}
      logFunction={logError as any}
      trackEventFunction={trackEventGA4}
    >
      <QueryWrapper
        handleLogout={handleLogoutClick}
        trackEventGA4={trackEventGA4}
        setTosState={setTosState}
      >
        <SessionProvider isAuthenticated={isAuthenticated}>
          <Growthbook>
            <LibContextWrapper
              trackEventGA4={trackEventGA4}
              logError={logError}
              trackEvent={trackEvent}
              isInInternationalLanguageTest={isInInternationalLanguageTest}
              isInInternationalVinTest={isInInternationalVinTest}
              isInOwnerSearchVariation={isInOwnerSearchVariation}
              isInSingleReportVariation={isInSingleReportVariation}
              isInAstrologyStandaloneVariation={
                isInAstrologyStandaloneVariation
              }
              isInRenameSidebarItemsVariation={isInRenameSidebarItemsVariation}
              handleLogoutClick={handleLogoutClick}
            >
              <EmailVerification>
                <SearchFormProvider>
                  <div className="app">
                    <HeaderContainer>
                      <SsoBannerContainer>
                        <SsoPromoBanner />
                        <BannerMigrationCta />
                      </SsoBannerContainer>
                      <HeaderParent
                        handleSearchSuccess={handleSearchSuccess}
                        changeSingleReportVariation={
                          changeIsInSingleReportVariation
                        }
                        changeOwnerSearchVariation={
                          changeIsInOwnerSearchVariation
                        }
                        changeInternationalLanguageTest={
                          changeIsInInternationalLanguageTest
                        }
                        changeInternationalVinTest={
                          changeIsInInternationalVinTest
                        }
                        changeIsInAstrologyStandaloneVariation={
                          changeIsInAstrologyStandaloneVariation
                        }
                        changeIsInRenameSidebarItemsVariation={
                          changeIsInRenameSidebarItemsVariation
                        }
                        changeSearchpalVariation={changeIsInSearchpalVariation}
                        changeStaypalChatbotVariation={
                          changeIsInStaypalChatbotVariation
                        }
                      />
                    </HeaderContainer>
                    <AppContainer>
                      <Routes>
                        <Route element={<NotFound />} path={'*'} />
                        <Route element={<ErrorPage />} path={'/error'} />
                        <Route index element={<Navigate to={`/dashboard`} />} />
                        <Route
                          path={'/family-tree'}
                          element={
                            <AuthenticatedRoute>
                              <FamilyTreePage />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/self-searching`}
                          element={
                            <AuthenticatedRoute>
                              <SelfSearchingContainer />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/login`}
                          element={
                            <PublicRoute>
                              <Login
                                onLoginSuccess={() =>
                                  onLoginSuccess(
                                    isInInternationalLanguageTest,
                                    isInInternationalVinTest
                                  )
                                }
                                ref={loginPageContainerRef}
                              />
                            </PublicRoute>
                          }
                        >
                          <Route
                            path="reset-password-request"
                            element={
                              <ResetPasswordRequestForm
                                containerRef={loginPageContainerRef}
                                open={false}
                              />
                            }
                          />
                          <Route
                            path="magic-link"
                            element={
                              <MagicLinkModal
                                containerRef={loginPageContainerRef}
                                open={false}
                              />
                            }
                          />
                          <Route
                            path="forgot-email"
                            element={
                              <ForgotLoginModal
                                containerRef={loginPageContainerRef}
                                open={false}
                              />
                            }
                          />
                        </Route>
                        <Route
                          path={`/reset-password`}
                          element={
                            <PublicRoute>
                              <ResetPassword />
                            </PublicRoute>
                          }
                        />
                        <Route
                          path={`dashboard`}
                          element={
                            <AuthenticatedRoute>
                              <DashboardParent
                                contactUsHandler={contactUsHandler}
                                handleLogout={handleLogoutClick}
                                openConfirmCancelModal={openConfirmCancelModal}
                              />
                            </AuthenticatedRoute>
                          }
                        >
                          <Route
                            index
                            element={
                              <AuthenticatedRoute>
                                <DashboardPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/reports`}
                            element={
                              <AuthenticatedRoute>
                                <RecentReports />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/personality-traits`}
                            element={
                              <AuthenticatedRoute>
                                <PersonalityTraitsPage />
                              </AuthenticatedRoute>
                            }
                          />
                          {isInAstrologyStandaloneVariation && (
                            <Route
                              path={'/dashboard/astrology'}
                              element={
                                <AuthenticatedRoute>
                                  <AstrologyPage />
                                </AuthenticatedRoute>
                              }
                            />
                          )}
                          <Route
                            path={`/dashboard/family-ancestry`}
                            element={
                              <AuthenticatedRoute>
                                <FamilyAndAncestryDashboard />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/family-album`}
                            element={
                              <AuthenticatedRoute>
                                <FamilyAlbum />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/rewards`}
                            element={
                              <AuthenticatedRoute>
                                <RewardsPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/dark-web`}
                            element={
                              <AuthenticatedRoute>
                                <DarkWebScan />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/lists`}
                            element={
                              <AuthenticatedRoute>
                                <Lists />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`/dashboard/monitoring`}
                            element={
                              <AuthenticatedRoute>
                                <Monitoring />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route path={'/dashboard/id-monitor'}>
                            <Route
                              index
                              element={
                                <AuthenticatedRoute>
                                  <IdMonitor />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={'credit-bureau-contacts'}
                              element={
                                <AuthenticatedRoute>
                                  <CreditBureauContacts />
                                </AuthenticatedRoute>
                              }
                            />
                            <Route
                              path={'alert-details'}
                              element={
                                <AuthenticatedRoute>
                                  <AlertDetails />
                                </AuthenticatedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path={'/dashboard/my-garage'}
                            element={
                              <AuthenticatedRoute>
                                <MyGarage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/dashboard/obituary'}
                            element={
                              <AuthenticatedRoute>
                                <ObituarySearchPage />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'/dashboard/language'}
                            element={
                              <AuthenticatedRoute>
                                <LanguageProvider />
                              </AuthenticatedRoute>
                            }
                          >
                            <Route index element={<LanguageDashboard />} />
                            <Route
                              path={'/dashboard/language/chat/:chatId'}
                              element={<ChatContainer />}
                            />
                            <Route
                              path={'/dashboard/language/lesson/:lessonChatId'}
                              element={<LessonChatContainer />}
                            />
                            <Route
                              path={'/dashboard/language/quiz/:quizChatId'}
                              element={<QuizChatContainer />}
                            />
                            <Route
                              path={'/dashboard/language/story/:storyChatId'}
                              element={<StoryChatContainer />}
                            />
                          </Route>
                          {isInRenameSidebarItemsVariation && (
                            <Route
                              path={'/dashboard/my-reports'}
                              element={
                                <AuthenticatedRoute>
                                  <MyReports />
                                </AuthenticatedRoute>
                              }
                            />
                          )}
                          <Route
                            path={'neighborhood-report'}
                            element={
                              <AuthenticatedRoute>
                                <NeighborhoodReportSection />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={'unclaimed-money'}
                            element={
                              <AuthenticatedRoute>
                                <UnclaimedMoney />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`billing`}
                            element={
                              <AuthenticatedRoute>
                                <Billing
                                  onCancelConfirmCancelModal={
                                    onCancelConfirmCancelModal
                                  }
                                />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path={`referrals`}
                            element={
                              <AuthenticatedRoute>
                                <Referrals />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path="fraud"
                            element={
                              <AuthenticatedRoute>
                                <Fraud onSearchSuccess={handleSearchSuccess} />
                              </AuthenticatedRoute>
                            }
                          />
                        </Route>
                        <Route
                          path={`/report/:reportType`}
                          element={
                            <AuthenticatedRoute>
                              <Report onSearchSuccess={handleSearchSuccess} />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/search/person`}
                          element={
                            <AuthenticatedRoute>
                              <SearchPersonResultsContainerv2 />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/search/v2`}
                          element={
                            <AuthenticatedRoute>
                              <SearchPersonResultsContainerv2 />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/search/listing`}
                          element={
                            <AuthenticatedRoute>
                              <SearchListingResults />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/search/obituary`}
                          element={
                            <AuthenticatedRoute>
                              <SearchObituaryResults />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/search/contact`}
                          element={
                            <AuthenticatedRoute>
                              <SearchPersonResultsContainerv2 />
                            </AuthenticatedRoute>
                          }
                        />
                        {isInOwnerSearchVariation && (
                          <Route
                            path={`/search/owner`}
                            element={
                              <AuthenticatedRoute>
                                <OwnerSearchParent />
                              </AuthenticatedRoute>
                            }
                          />
                        )}
                        <Route
                          path={`/noncompliance`}
                          element={
                            <AuthenticatedRoute>
                              <NonCompliancePage />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/compliance-form`}
                          element={
                            <AuthenticatedRoute>
                              <ComplianceFormPage link="/noncompliance" />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/compliance-permitted`}
                          element={
                            <AuthenticatedRoute>
                              <CompliancePermittedPage />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/compliance-prohibited`}
                          element={
                            <AuthenticatedRoute>
                              <ComplianceProhibitedPage />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={`/compliance-recomply`}
                          element={
                            <AuthenticatedRoute>
                              <ComplianceRecomplyPage />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={'/reactivation'}
                          element={
                            <AuthenticatedRoute>
                              <Reactivation />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          index={true}
                          path={'/upgrade/plan'}
                          element={
                            <AuthenticatedRoute>
                              <UpgradePlanPage
                                contactUsHandler={contactUsHandler}
                              />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path={'/captcha'}
                          element={
                            <AuthenticatedRoute>
                              <ReportVelocityCaptcha />
                            </AuthenticatedRoute>
                          }
                        />
                      </Routes>
                      <Recycling isAuthenticated={isAuthenticated} />
                    </AppContainer>
                    <ContactOptionsModal
                      isOpen={isContactOptionsModalOpen}
                      setIsOpen={setIsContactOptionsModalOpen}
                      openConfirmCancelModal={openConfirmCancelModal}
                      plansWithout5ReportBlocker={
                        constants.data.plansWithout5ReportBlocker
                      }
                      validReportCountThreshold={
                        constants.data.validReportCountThreshold
                      }
                      blockUpgradeWindowThreshold={
                        constants.data.blockUpgradeWindowThreshold
                      }
                    />
                    <ConfirmCancelModal
                      isOpen={isConfirmCancelModalOpen}
                      onClose={() => setIsConfirmCancelModalOpen(false)}
                      onCancel={() => {
                        setIsConfirmCancelModalOpen(false);
                        setIsCancellationReasonsModalOpen(true);
                      }}
                      openSuccessModal={openSuccessModal}
                      showDownsell={true}
                      showCheaperPlanLink={showCheaperPlanLink}
                    />
                    <CancellationReasonsModal
                      isOpen={isCancellationReasonsModalOpen}
                      onClose={(reason) => {
                        handleCancellationModalClose(reason);
                      }}
                    />
                    <TosModalLegalError
                      tosState={tosState}
                      setTosState={setTosState}
                      handleSearchSuccess={handleSearchSuccess}
                    />
                    <CancelDownsellOfferModal
                      isOpen={isDownsellOfferModalOpen}
                      bgImage={
                        isMobile
                          ? constants.images.cancelDownsellBackgroundMobile
                          : constants.images.cancelDownsellBackground
                      }
                      onClose={() => {
                        setIsDownsellOfferModalOpen(false);
                        setIsCancelFinalStepModalOpen(true);
                      }}
                    />
                    <CancelFinalStepModal
                      isOpen={isCancelFinalStepModalOpen}
                      onClose={() => setIsCancelFinalStepModalOpen(false)}
                    />
                    {isUserExperienceSurveyModalOpen && (
                      <CancelUserExperienceModal
                        onClose={(rating) =>
                          handleCloseUserExperienceSurveyModal(rating)
                        }
                      />
                    )}
                    <TrustPilotModal
                      isOpen={isTrustPilotModalOpen}
                      onClose={() => setIsTrustPilotModalOpen(false)}
                    />
                    <StaypalChatbotModal
                      isOpen={isStaypalChatbotModalOpen}
                      handleClose={() =>
                        closeStaypalModalandOpenPersistentView()
                      }
                    />
                    {showStaypalModalPersistentView() && (
                      <StaypalChatbotPersistentView />
                    )}
                    <MobileDownloadGlobalBanner />
                    {showFooter && (
                      <Footer
                        footerLinks={generateFooterLinks({
                          contactUsHandler,
                          isInternationalTestUser:
                            isInInternationalLanguageTest ||
                            isInInternationalVinTest,
                        })}
                        libVersion={
                          <LibVersion>
                            {import.meta.env.VITE_TARGET_ENV ===
                            'production' ? (
                              <code>{version}</code>
                            ) : (
                              <code>
                                {packageInfo.dependencies['@ltvco/refresh-lib']}
                              </code>
                            )}
                          </LibVersion>
                        }
                        hasMobileApps
                        openConfirmCancelModal={openConfirmCancelModal}
                      />
                    )}
                  </div>
                </SearchFormProvider>
              </EmailVerification>
            </LibContextWrapper>
          </Growthbook>
        </SessionProvider>
      </QueryWrapper>
    </ErrorBoundary>
  );
}

export default App;
